import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { parse } from 'csv-parse/browser/esm/sync';
import {
  capitalize,
  compact,
  filter as _filter,
  find,
  findIndex,
  first,
  forEach,
  get,
  isDate,
  isEmpty,
  last,
  reject,
  sortBy,
  without
} from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { DragDirective, DropListDirective, MenuComponent, TabChangeEvent } from '@epsilon/core-ui';

import { LoadAttributeDetails } from '../audience-builder/audience-builder.actions';
import { AppState } from '../reducers';
import { selectAudiences } from '../audience/audience.reducer';
import {
  selectAttributeDetails,
  selectDataTypeOperators,
  selectDisplayFullSegmentPath
} from '../audience-builder/audience-builder.reducer';
import { selectContext } from '../context/context.reducer';
import { EnumSetValues } from '../admin/admin.models';
import {
  activeOperatorTabNames,
  ActiveOperatorTabType,
  ATTRIBUTE,
  AttributeDetail,
  AttributeDetails,
  AudienceExpressionGroup,
  AudienceRuleGroup,
  BuilderAttribute, EnumValueDescriptor,
  RELATIVE_VALUE,
  VALUE
} from '../audience-builder/audience-builder.models';
import { DataTypeOperator, FunctionTypeOperator } from '../models/data-type-operator';
import {
  getAttributeDetail,
  getCabAttributeDetail,
  hasExpressionGroup,
  setFullSegmentPath
} from '../audience/audience.utils';
import {
  FEATURE_AGGREGATIONS,
  FEATURE_AUDIENCE_BUILDER_ENUM_SELECTION, FEATURE_CAB_ATTRIBUTE_ACTIONS,
  FEATURE_RELATIVE_DATES
} from '../utils/feature-utils';
import { BUILDER_TXT, expressionDataType, isDateNumeric, isDateType, isDefined } from '../utils/utils';
import { DataType } from '../enums/data-types';
import { AdminService } from '../admin/admin.service';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { CountsService } from '../counts/counts.service';
import { DetailsModalService } from '../services/details-modal.service';
import { DragDropService } from '../services/drag-drop.service';
import { CabConstants } from '../cab.constants';
import { SetUnsavedChangesState } from '../hasUnsavedChanges/hasUnsavedChanges.actions';
import { FeatureService } from '../utils/feature-service';
import { AudienceService } from '../audience/audience.service';

// represents a pill within a definition dragged from the picker
@UntilDestroy()
@Component({
  selector: 'lib-builder-attribute',
  templateUrl: './builder-attribute.component.html',
  styleUrls: ['./builder-attribute.component.sass'],
})
export class BuilderAttributeComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() attributeIndex: number;
  @Input() fromAggregationGroup: boolean;
  @Input() attribute: AudienceExpressionGroup;
  @Input() group: Array<AudienceRuleGroup | AudienceExpressionGroup>;
  @Input() isPreview = false;
  @Input() isAggregator = false;
  @Input() isAggregatorDropped = false;
  @Input() hideTrashIcon = false;
  @Input() attributeEnumValues?: EnumSetValues[] = [];
  @Input() dedupeType: UntypedFormControl;
  @Output() attributeValidation: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() attributeDelete: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild('actionsMenu') public actionsMenu: MenuComponent;
  @ViewChild('relativeDateMenuMulti')
  private relativeDateMenuMulti: MenuComponent;
  attributeFilteredEnumValues: EnumSetValues[] = [];

  filterValueOnEdit ="";
  dataTypeRef = DataType;
  aggregationsEnabled: boolean;
  showCabAttributeActions: boolean;
  relativeDatesEnabled: boolean;
  isEnumFeatureEnabled: boolean;
  expressionDataType = expressionDataType;
  operators: DataTypeOperator[];
  filteredFilters: DataTypeOperator[];
  initialOperators: DataTypeOperator[];
  filteredFilters$: BehaviorSubject<DataTypeOperator[]> = new BehaviorSubject(
    null
  );
  attributeCompareOperators: DataTypeOperator[];
  relativeValueOperators: DataTypeOperator[];
  activeFilter$: BehaviorSubject<any> = new BehaviorSubject({});
  public minDatePickerMaxDate$: BehaviorSubject<Date> =
    new BehaviorSubject<Date>(null);
  public maxDatePickerMinDate$: BehaviorSubject<Date> =
    new BehaviorSubject<Date>(null);
  public attributeForm = new UntypedFormGroup({
    activeFilter: new UntypedFormControl('', [Validators.required]),
    filterValue: new UntypedFormControl(''),
    multiFilterValue: new UntypedFormControl(''),
    aggregation: new UntypedFormControl(''),
    relativeDateNum: new UntypedFormControl(''),
    relativeDateUnits: new UntypedFormControl('Days'),
    relativeDateOperator: new UntypedFormControl('Ago'),
    relativeDateRadio: new UntypedFormControl('custom'),
    relativeDateNumMulti: new UntypedFormControl(''),
    relativeDateUnitsMulti: new UntypedFormControl('Days'),
    relativeDateOperatorMulti: new UntypedFormControl('Ago'),
    relativeDateRadioMulti: new UntypedFormControl('custom'),
  });
  dateNumeric = isDateNumeric;
  comparedAttribute$: BehaviorSubject<BuilderAttribute | AttributeDetail> =
    new BehaviorSubject(null);
  @ViewChild(DropListDirective) dropList?: DropListDirective;
  activeOperatorTabNames: string[] = [];
  activeOperatorTab$: BehaviorSubject<ActiveOperatorTabType> =
    new BehaviorSubject(VALUE);
  dropDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  displayActions = false;
  actions = [{ display: 'Add Aggregation', toggled: false, disabled: false }]; // 'Add Sub Query'
  aggregations: FunctionTypeOperator[] = [];
  isAggregationToggled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dateRelativeLabel$ = new BehaviorSubject('Set Value');
  dateRelativeLabelMulti$ = new BehaviorSubject('Set Value');
  relativeDate$: BehaviorSubject<string> = new BehaviorSubject(null);
  relativeDateMulti$: BehaviorSubject<string> = new BehaviorSubject(null);
  dedupeType$ = this.store.select(selectAudiences).pipe(
    filter(() => this.isCabAttribute),
    map((audiences) => {
      const isAudience =
        get(this.attribute, 'expression.operand.valueType')?.toLowerCase() ===
        DataType.AUDIENCE_LIST;
      const key = isAudience ? 'audienceListId' : 'audienceDefinitionId';
      const id = get(this.attribute, ['expression', 'operand', key]);
      const cabAttributes = isAudience
        ? audiences['audiences']
        : audiences['audienceDefinitions'];
      return get(find(cabAttributes, { id }), 'dedupeIdentityType');
    })
  );
  attributeDetails: AttributeDetails;
  attributeDetail: AttributeDetail;
  displayFullSegmentPath$ = this.store.select(selectDisplayFullSegmentPath);
  attributePath$ = new BehaviorSubject(null);
  comparedAttributePath$ = new BehaviorSubject(null);
  aggregatorDropBox: boolean;
  isEnumSingleDropDownAttribute: boolean;
  isEnumMultiDropDownAttribute: boolean;
  isProductTypeDcdp: boolean;
  isTabChangeActivate:boolean;
  isBuilderView:boolean;
  constructor(
    public store: Store<AppState>,
    public dragDropService: DragDropService,
    public detailsModalService: DetailsModalService,
    public countsService: CountsService,
    public builderService: AudienceBuilderService,
    public adminService: AdminService,
    public audienceService: AudienceService,
    private route: ActivatedRoute,
    public featureService: FeatureService
  ) {
    this.isBuilderView = this.route.snapshot.url.map(item=>item.path).join('/').includes(`${BUILDER_TXT}/view`)
  }

  ngOnInit(): void {
    const attr = this.attribute;
    this.aggregationsEnabled = this.featureService.isFeatureEnabled(FEATURE_AGGREGATIONS);
    this.isEnumFeatureEnabled = this.featureService.isFeatureEnabled(FEATURE_AUDIENCE_BUILDER_ENUM_SELECTION);
    this.relativeDatesEnabled = this.featureService.isFeatureEnabled(FEATURE_RELATIVE_DATES);
    this.showCabAttributeActions = this.featureService.isFeatureEnabled(FEATURE_CAB_ATTRIBUTE_ACTIONS);
    this.activeOperatorTabNames = this.relativeDatesEnabled
    ? activeOperatorTabNames
    : without(activeOperatorTabNames, RELATIVE_VALUE);
    if (attr?.path) {
      setFullSegmentPath(this.attributePath$, this.attribute);
    } else if (attr?.expression?.operand?.path) {
      setFullSegmentPath(this.attributePath$, attr.expression.operand);
    } else if (attr?.expression?.firstOperand?.path){
      setFullSegmentPath(this.attributePath$, attr.expression.firstOperand);
    }

   this.attributeForm.get('activeFilter')
    .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
    .subscribe((value: any) => {
      this.onSelectChange(value);
    });

    this.attributeForm.get('aggregation')
    .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
    .subscribe((aggre: any) => {
      if (this.attribute?.expression?.firstOperand?.function !== aggre?.functionType) {
        this.setAggregationOperator(aggre);
      }
    });

    this.store
      .select(selectAttributeDetails)
      .pipe(untilDestroyed(this))
      .subscribe((details) => {
        this.attributeDetails = details;
        this.attributeDetail = getAttributeDetail(
          details,
          this.attribute?.expression
        );
        if (this.isCabAttribute) {
          const cabAttributeDetail = getCabAttributeDetail(
            details,
            this.attribute?.expression
          );
          if (cabAttributeDetail) {
            setFullSegmentPath(this.attributePath$, cabAttributeDetail);
          }
        }
        if (this.attributeDetail && !(this.attributeDetails[this.attribute.expression.firstOperand.cabId]?.cabId === this.attribute?.expression?.firstOperand?.cabId)) {
          setFullSegmentPath(this.attributePath$, this.attributeDetail);
        }
      });

    this.store
      .select(selectDataTypeOperators)
      .pipe(filter(isDefined), untilDestroyed(this))
      .subscribe((operators) => {
        if (operators.length) {
          this.operators = this.attributeOperators(operators, 'operators');
          this.aggregations = this.attributeOperators(operators, 'functions');
          if(this.aggregations.length < 1) {
            find(this.actions, { display: 'Add Aggregation' }).disabled = true;
          } else {
            find(this.actions, { display: 'Add Aggregation' }).disabled = false;
          }
          this.filteredFilters$.next(this.operators);
          this.attributeCompareOperators = _filter(
            this.operators,
            'attributeAsAdditionalOperand'
          );
          this.relativeValueOperators = _filter(
            this.operators,
            'relativeOperand'
          );
          this.filteredFilters = this.operators;
          this.initialOperators = operators ?? [];
          this.setDefaultValue();
        }
      });

    this.conditionalValidations(
      'filterValue',
      this.activeFilter$.getValue()?.singleOperand
    );
    this.conditionalValidations(
      'multiFilterValue',
      this.activeFilter$.getValue()?.dualValue
    );

    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.isProductTypeDcdp = true;
        }
      });

    // Listen for date value changes and update min/max values accordingly
    this.handleDateValueChanges('filterValue', this.maxDatePickerMinDate$);
    this.handleDateValueChanges('multiFilterValue', this.minDatePickerMaxDate$);
    if(this.isAggregator)
    {
      this.toggleAction(this.aggregationAction, true);
    }
    this.builderService.isElementManuallyDropped = false;
  }

  ngAfterViewInit() {
    this.attributeForm.valueChanges.subscribe(() => {
      if(this.attributeForm.dirty) {
        this.builderService.audienceBuilderUpdatedManually$.next(true);
        this.builderService.audienceBuilderCriteriaManually$.next(true);
      }
    });
    setTimeout(() => {
      if (this.dropList) {
        // Registers the compared attribute drop list
        this.dragDropService.register(this.dropList.id);
      }

      this.activeOperatorTab$.next(
        this.attribute.expression?.secondOperand?.valueType === 'Literal'
          ? this.attribute.expression?.secondOperand?.relativeDate
            ? RELATIVE_VALUE
            : VALUE
          : ATTRIBUTE
      );
      this.attributeFilteredEnumValues = this.attributeEnumValues;
    }, 1000);
  }

  ngOnChanges(changes) {
    if (changes['attributeEnumValues']) {
      this.attributeEnumValues = changes['attributeEnumValues'].currentValue;
      this.attributeFilteredEnumValues = this.attributeEnumValues;
    }
  }

  // handles changes after switching tabs in attribute filter select (ATTRIBUTE, VALUE, RELATIVE_VALUE)
  tabChange(event: TabChangeEvent, group, index) {
    const tabName = get(event, 'tab.label');
    if(tabName === VALUE){
      delete group[index].expression.secondOperand.relativeDate;
    }
    this.activeOperatorTab$.next(tabName as ActiveOperatorTabType);
    let activeFilters;
    if (this.isAttributeCompare()) {
      activeFilters = this.attributeCompareOperators;
    } else if (this.isRelativeValue()) {
      activeFilters = this.relativeValueOperators;
    } else {
      activeFilters = this.operators;
    }
    this.filteredFilters$.next(activeFilters);
    this.toggleOperandValueType(
      group[index].expression.secondOperand,
      this.isAttributeCompare() ? ATTRIBUTE : 'Literal'
    );
    const filtersOperator = get(
      this.attribute,
      'expression.comparisonOperator'
    );
    const operator =
      find(this.filteredFilters$.value, {
        operator: filtersOperator,
      }) || first(this.filteredFilters$.value);
    this.onSelectChange(operator);
    this.isTabChangeActivate = true;
  }

  isTabDisabled(tabName: ActiveOperatorTabType) {
    return (
      (tabName === ATTRIBUTE && !this.attributeCompareOperators?.length) ||
      (tabName === RELATIVE_VALUE && !this.relativeValueOperators?.length)
    );
  }

  activeOperatorTabs() {
    return reject(this.activeOperatorTabNames, (tabName) =>
      this.isTabDisabled(tabName as ActiveOperatorTabType)
    );
  }

  get aggregationAction() {
    // TODO: since aggregation data will change, for now, just relying on display data throughout
    return find(this.actions, { display: 'Add Aggregation' });
  }

  // AudienceDefinition || AudienceList
  get isCabAttribute(): boolean {
    return !!this.attribute.expression?.operand;
  }

  get cabAttributeValueType(): string {
    const dataType =
      this.attribute.expression?.operand?.valueType?.toLowerCase();
    return capitalize(dataType.replace(/audience/, ''));
  }

  getCabAttributeDisplayName(attribute): string {
    const cabId = get(attribute, 'expression.operand.cabId');
    return get(
      this.attributeDetails[cabId],
      'displayName',
      attribute.displayName
    );
  }

  onHoverLeave() {
    if (this.actionsMenu?.menuVisible) {
      return;
    }
    this.displayActions = false;
  }

  public expressionControlReset() {
    this.dateRelativeLabel$.next('Set Value');
    this.dateRelativeLabelMulti$.next('Set Value');
    this.attributeForm.get('filterValue').setValue('');
    this.attributeForm.get('multiFilterValue').setValue('');
    this.attributeForm.get('relativeDateNum').setValue('');
    this.attributeForm.get('relativeDateUnits').setValue('Days');
    this.attributeForm.get('relativeDateOperator').setValue('Ago');
    this.attributeForm.get('relativeDateRadio').setValue('custom');
    this.attributeForm.get('relativeDateNumMulti').setValue('');
    this.attributeForm.get('relativeDateUnitsMulti').setValue('Days');
    this.attributeForm.get('relativeDateOperatorMulti').setValue('Ago');
    this.attributeForm.get('relativeDateRadioMulti').setValue('custom');
    this.attribute.expression.secondOperand.relativeDate = [];
  }

  public onSelectChange(options) {
    this.attributeFilteredEnumValues = this.attributeEnumValues
    if (!isEmpty(options)) {
      this.resetDateFormValues(this.activeFilter$.getValue(), options);
      this.activeFilter$.next(options);
      this.setEnumType();
      this.attributeForm.get('activeFilter').setValue(options, { emitEvent: false });
      if(this.isTabChangeActivate && this.isRelativeValue()){
        this.expressionControlReset();
      }
      if (this.activeFilter$.getValue()) {
        this.combineFilter();
      }
      this.attribute.expression.comparisonOperator = options.operator;
    }
  }

  // Set values when loading an existing builder attribute
  setDefaultValue() {
    this.setSecondOperandDataTypeFromAttributeDetail();
    const values = get(this.attribute, 'expression.secondOperand.values');
    const filtersOperator = get(
      this.attribute,
      'expression.comparisonOperator'
    );

    this.setAggregationOperator();
    let filtersValue = first(values);
    let filtersMultiValue = last(values);
    const hasFiltersOperator = !isEmpty(filtersOperator);
    const hasFiltersValue = !isEmpty(filtersValue?.toString());
    const filter = find(this.operators, {
      operator: hasFiltersOperator
        ? filtersOperator
        : first(this.operators)?.operator,
    });
    const hasFiltersMultiValue =
      filter?.dualValue && !isEmpty(filtersMultiValue?.toString());
    this.activeFilter$.next(filter);
    this.attributeForm.get('activeFilter').setValue(filter);
    let comparedAttribute = get(
      this.attribute,
      'expression.secondOperand.comparedAttribute'
    );
    if (comparedAttribute) {
      comparedAttribute = {...this.attributeDetails[comparedAttribute.cabId], name: this.attributeDetails[comparedAttribute.cabId].name || ''};
      setFullSegmentPath(this.comparedAttributePath$, comparedAttribute);
      this.comparedAttribute$.next(comparedAttribute);
      this.activeOperatorTab$.next(ATTRIBUTE);
      this.filteredFilters$.next(this.attributeCompareOperators);
      this.store.dispatch(new SetUnsavedChangesState(null));
      return;
    }
    filtersValue = this.setDefaultValues(
      hasFiltersValue,
      filter,
      filtersValue,
      values
    );
    filtersMultiValue = this.setDefaultValues(
      hasFiltersMultiValue,
      filter,
      filtersMultiValue,
      values
    );
    if(this.isEnumFeatureEnabled && this.attributeDetail && (this.attributeDetail as any)?.entityEnumId) {
      const entityEnumId  = {...this.attributeDetail}?.entityEnumId;
      if(entityEnumId) {
        this.audienceService.getEnumValues(this.route.snapshot.paramMap.get('contextId'), entityEnumId).subscribe(res => {
          this.attributeEnumValues = res.values;
          this.attributeFilteredEnumValues = res.values;
          this.setEnumType();
          if(this.isEnumMultiDropDownAttribute && this.attributeEnumValues?.length > 0 && !isEmpty(filtersValue)) {
             this.attributeForm.get('filterValue').setValue(filtersValue.split(','));
             this.filterValueOnEdit = filtersValue.split(',');
          } else if(!isEmpty(filtersValue)) {
            this.attributeForm.get('filterValue').setValue(filtersValue);
            this.filterValueOnEdit = filtersValue;
          }
        });
      }
    } else {
      this.attributeForm.get('filterValue').setValue(filtersValue);
      this.filterValueOnEdit = filtersValue;
    }
    this.attributeForm.get('multiFilterValue').setValue(filtersMultiValue);
    if (
      !isEmpty(get(this.attribute, 'expression.secondOperand.relativeDate'))
    ) {
      this.activeOperatorTab$.next(RELATIVE_VALUE);
      this.filteredFilters$.next(this.relativeValueOperators);
    }
    if(!this.builderService.isElementManuallyDropped) {
      this.attributeForm.markAsPristine();
      this.attributeForm.markAsUntouched();
      this.builderService.audienceBuilderUpdatedManually$.next(false);
      this.builderService.audienceBuilderCriteriaManually$.next(false);
      this.store.dispatch(new SetUnsavedChangesState(null));
    }
  }

  removeAudienceDefinition(listItems, idx) {
    // when an attribute is removed from builder group, and if count is processing,
    // stop count processing
    this.countsService.stopCron();
    this.countsService.resetBuilderCounts();
    listItems.splice(idx, 1);
    if (listItems.length === 1 && !this.fromAggregationGroup) {
      listItems.splice(0, 1);
    }
    this.clearDeletedAttributes(listItems);
    this.attributeDelete.emit(true);
    if(!this.isAggregationToggled$.value && !this.isAggregator)
    {
      this.isAggregator = true;
    }
    this.builderService.audienceBuilderUpdatedManually$.next(true);
    this.builderService.audienceBuilderCriteriaManually$.next(true);
  }

  clearDeletedAttributes(container) {
    const hasExpression = hasExpressionGroup(container);
    const hasAggregateConditions = container.some((item) => item.aggregationConditions?.length > 0);
    if (!hasExpression && !hasAggregateConditions) {
      container.forEach((x) => {
        const idx = findIndex(container, x);
        container.splice(idx, 1);
      });
    }
  }

  removeComparedAttribute(listItems, idx) {
    this.comparedAttribute$.next(null);
    listItems[idx].expression.secondOperand.comparedAttribute = null;
    this.comparedAttributePath$.next(null);
  }

  toggleOperandValueType(operand, valueType) {
    operand.valueType = valueType;
  }

  get index() {
    return findIndex(this.group, this.attribute);
  }

  // Update expression operand values
  combineFilter() {
    if (this.isCabAttribute) {
      return;
    }
    const activeFilter = this.activeFilter$.getValue();
    const activeFilterValue =
      activeFilter.singleOperand && this.attributeForm.get('filterValue').value
        ? this.attributeForm.get('filterValue').value
        : null;
    const multiActiveFilterValue =
      activeFilter.dualValue && this.attributeForm.get('multiFilterValue').value
        ? this.attributeForm.get('multiFilterValue').value
        : null;
    const values = this.setSecondOperandValues(
      activeFilter,
      activeFilterValue,
      multiActiveFilterValue
    );

    this.resetActiveFilter(activeFilter);
    this.attribute.expression.comparisonOperator = activeFilter.operator;
    this.attribute.expression.secondOperand = {
      ...this.attribute.expression.secondOperand,
      values,
    };
    if(this.isEnumFeatureEnabled && this.attributeEnumValues?.length > 0) {
      this.attribute.expression.secondOperand.valueDescriptors = this.getEnumValueDescriptor(values);
    } else {
      this.attribute.expression.secondOperand.valueDescriptors = [];
    }
  }

  resetActiveFilter(activeFilter) {
    const filterValue = this.attributeForm.get('filterValue').value;
    if (
      !activeFilter.multiValue &&
      Array.isArray(filterValue) && filterValue.length > 1
    ) {
      this.attributeForm.get('filterValue').setValue('');
      this.attributeForm.get('filterValue').markAsDirty();
      this.filterValueOnEdit = '';
    }
  }

  isSeparator(operators, index) {
    return operators[index - 1] && operators[index - 1].separator;
  }

  attributeOperators(operators, operatorType: 'operators' | 'functions') {
    const dataType = this.aggregationAction?.toggled ? capitalize(this.attribute?.expression?.secondOperand?.attributeDataType?.toLowerCase() ?? this.attribute?.expression?.secondOperand?.dataType?.toLowerCase()) : (
      capitalize(this.attributeDataType()) ||
      this.attribute.expression.operand?.valueType);
    return sortBy(
      find(operators, {
        dataType,
      })?.[operatorType],
      'sortOrder'
    );
  }

  attributeDataType() {
    return (
      (this.aggregationAction?.toggled && this.attributeForm.get('aggregation')?.value?.returnDataType) || (this.attributeDetail?.dataType ||
      get(this.attribute, 'expression.secondOperand.dataType'))
    )?.toLowerCase();
  }

  attributeComparisonOperator() {
    return get(this.attribute, 'expression.comparisonOperator');
  }

  getMaxLength() {
    // if (this.attributeDataType() !== this.dataTypeRef.STRING) return null;
    if (this.enumMultiSelectDropDownConditionalList.includes(this.attributeComparisonOperator())) return null;
    return 255;
  }

  resetDateFormValues(previousOperator, currentOperator) {
    if (
      isDateType(this.attributeDataType()) &&
      ((isDateNumeric(previousOperator?.operator) &&
        !isDateNumeric(currentOperator?.operator)) ||
        (isDateNumeric(currentOperator?.operator) &&
          !isDateNumeric(previousOperator?.operator)))
    ) {
      forEach(['filterValue', 'multiFilterValue'], (formField) =>
        this.attributeForm.get(formField).setValue(null)
      );
    }
  }

  handleDateValueChanges(formField: string, dateValue: BehaviorSubject<Date>) {
    this.attributeForm
      .get(formField)
      .valueChanges.pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(() => isDateType(this.attributeDataType()))
      )
      .subscribe((val) =>
        isDate(val) ? dateValue.next(val) : dateValue.next(null)
      );
  }

  conditionalValidations(formField, condition) {
    this.attributeForm
      .get(formField)
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((val) => {
        const input = this.attributeForm.get(formField);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        condition
          ? input.setValidators(Validators.nullValidator)
          : input.clearValidators();
        this.attributeForm.get(formField).setValue(val);
        input.setErrors({ limitExceeded: false });
        this.builderService.attributeFromFilterValueErrorMap[this.attributeIndex] = false;
        this.combineFilter();
        input.updateValueAndValidity();
        this.attributeForm.updateValueAndValidity();
        if (formField === 'filterValue' && 
          this.enumMultiSelectDropDownConditionalList.includes(this.attributeComparisonOperator())) {
            if (val.split(',')?.length > 100) {
              input.markAsDirty();
              input.setErrors({ limitExceeded: true });
              this.builderService.attributeFromFilterValueErrorMap[this.attributeIndex] = true;
            }
        }
        this.attributeValidation.emit(this.attributeForm.valid);
      });
  }

  public onDropped(event: any, group, index): void {
    if (!this.isDropAllowed(event)) {
      this.enableDrop();
      return;
    }

    if (!(event.previousContainer.data instanceof Array)) {
      const previousContainerData = event.previousContainer.data;
      event.previousContainer.data = [];
      event.previousContainer.data.push(previousContainerData);
    }
    this.comparedAttribute$.next(
      event.previousContainer.data[event.previousIndex]
    );
    this.toggleOperandValueType(
      group[index].expression.secondOperand,
      ATTRIBUTE
    );
    const comparedAttribute = this.comparedAttribute$.value;
    setFullSegmentPath(this.comparedAttributePath$, comparedAttribute);
    const attributeDetailsMap = {};
    attributeDetailsMap[comparedAttribute?.cabId] = {
      id: comparedAttribute?.cabId,
      displayName: comparedAttribute?.displayName
    };
    this.store.dispatch(new LoadAttributeDetails(attributeDetailsMap));

    group[index].expression.secondOperand.comparedAttribute = {
      id: comparedAttribute.id,
      cabId: comparedAttribute.cabId,
      valueType: ATTRIBUTE,
      name: comparedAttribute.name,
      displayName: comparedAttribute.displayName,
      dataSetId: comparedAttribute.rootId,
      dataType: comparedAttribute.dataType,
      path: comparedAttribute.path,
      valueDescriptors: []
    };
  }

  enumSingleSelectDropDownConditionalList = ['EQ', 'NEQ'];
  enumMultiSelectDropDownConditionalList = ['IN', 'NOT_IN'];

  isDropAllowed(event: any) {
    const isDraggingPickerNode = get(
      event,
      'item.element.nativeElement.className'
    )?.includes('pcbc-nav-tree-leaf-drag');
    if (!(event.item?.dropContainer?.data instanceof Array)) {
      const previousContainerData = event.item.dropContainer.data;
      event.item.dropContainer.data = [];
      event.item.dropContainer.data.push(previousContainerData);
    }

    const draggedAttribute = get(event, 'item.dropContainer.data[0]');

    const hasSameDataType =
      draggedAttribute.dataType?.toLowerCase() === this.attributeDataType();
    const isDifferentAttribute =
      draggedAttribute.cabId !==
      get(this.attribute, 'expression.firstOperand.cabId');
    const dropAllowed =
      isDraggingPickerNode &&
      !compact(event.container.data).length &&
      hasSameDataType &&
      isDifferentAttribute;
    this.dropDisabled$.next(!hasSameDataType);
    return dropAllowed;
  }

  isDropAllowedEnterPredicate(
    drag: DragDirective,
    drop: DropListDirective
  ): boolean {
    const isDraggingPickerNode = get(
      drag,
      'element.nativeElement.className'
    )?.includes('pcbc-nav-tree-leaf-drag');
    return isDraggingPickerNode && !compact(drop.data).length;
  }

  enableDrop() {
    this.dropDisabled$.next(false);
  }

  isActiveTab(tabName: string) {
    return this.activeOperatorTab$.value === tabName;
  }

  setSecondOperandValues(filter, firstInputValue, secondInputValue) {
    let values = null;
    if (filter.multiValue) {
      try {
        if(firstInputValue && Array.isArray(firstInputValue)) {
          values = firstInputValue;
        } else {
          values = parse(firstInputValue,{})[0];
        }
      } catch(err) {
        console.error('exception while parsing multiValue values:::', err);
      }
    } else if (filter.singleOperand || filter.dualValue) {
      if(firstInputValue && Array.isArray(firstInputValue)) {
        values = [firstInputValue[0]];
      } else {
        values = compact([firstInputValue, secondInputValue]);
      }
    }

    return values;
  }

  setDefaultValues(hasValue, filter, filterValue, values) {
    let inputValue = '';
    if (hasValue) {
      if(filter.multiValue) {
        values?.forEach((value, idx) => {
          let comma = ',';
          if(idx === 0) {
            comma = '';
          }
          if(value?.includes('"')) {
            inputValue = inputValue.concat(comma, '"', value.replaceAll('"', '""'), '"');
          } else if(value?.includes(',')) {
            inputValue = inputValue.concat(comma, '"', value, '"');
          } else {
            inputValue = inputValue.concat(comma, value);
          }
        });
      } else {
        inputValue = filterValue;
      }
    }
    return inputValue;
  }

  isAttributeCompare() {
    return this.activeOperatorTab$.value === ATTRIBUTE;
  }

  isRelativeValue() {
    return this.activeOperatorTab$.value === RELATIVE_VALUE;
  }

  toggleAction(item, shouldToggleOn?: boolean) {
    const action = find(this.actions, { display: item.display });
    action.toggled = shouldToggleOn ? true : !action.toggled;
    if(!action.toggled) {
      this.isAggregator = true;
      this.dragDropService.addAggregationButtonClick = true;
    }
    if (action.display === 'Add Aggregation') {
      const aggregationFormControl = this.attributeForm.get('aggregation');
      if (action.toggled) {
        this.isAggregationToggled$.next(true);
        this.operators = this.attributeOperators(this.initialOperators, 'operators');
        this.aggregations = this.attributeOperators(this.initialOperators, 'functions');
      } else {
        this.isAggregationToggled$.next(false);
      }
      aggregationFormControl.setValidators([Validators.required]);
      aggregationFormControl.updateValueAndValidity();
    }
  }

  resetAggregation(event?) {
    if (event) {
      event.stopPropagation();
    }
    const operandToUpdate = this.attribute.expression.firstOperand;
    this.isAggregationToggled$.next(false);
    this.toggleOperandValueType(operandToUpdate, ATTRIBUTE);
    operandToUpdate.function = null;
    this.toggleAction(this.aggregationAction);
  }

  setAggregationOperator(aggregation?: FunctionTypeOperator) {
    const defaultAggregationOperator = get(
      this.attribute,
      'expression.firstOperand.function'
    );
    // const operandToUpdate = this.attribute.aggregationConditions ? get(this.attribute.aggregationConditions[0], 'expression.firstOperand') : this.attribute.expression.firstOperand;
    const operandToUpdate =  this.attribute.expression.firstOperand;

    if (isEmpty(aggregation) && defaultAggregationOperator) {
      this.toggleAction(this.aggregationAction, true);
    }
    const firstAgg = get(first(this.aggregations), 'functionType');
    const aggOperator =
      aggregation?.functionType || defaultAggregationOperator || firstAgg;
    const agg: any = this.getAggregationByFunction(aggOperator);

    this.attributeForm.get('aggregation').setValue(agg, { emitEvent: false });
    if(this.aggregationAction?.toggled && agg) {
      this.attribute.expression.secondOperand = {
        ...this.attribute.expression.secondOperand,
        dataType: agg?.returnDataType,
        ...!this.attribute?.expression?.secondOperand?.attributeDataType && {attributeDataType: this.attribute.expression.secondOperand?.dataType?.toLowerCase()}
      };
    }
    if (this.isAggregationToggled$.value) {
      this.toggleOperandValueType(operandToUpdate, 'Function');
      operandToUpdate.function = agg?.functionType;
      if(!this.fromAggregationGroup) {
        this.group[this.attributeIndex] = { logicalOperator: 'AND', group: [], aggregationConditions: [{...this.attribute}] };
      }
    }
  }

  getAggregationByFunction(functionType): FunctionTypeOperator {
    return find(this.aggregations, { functionType });
  }

  setSecondOperandDataTypeFromAttributeDetail() {
    if (this.attributeDetail) {
      this.attribute.expression.secondOperand = {
          ...this.attribute.expression.secondOperand,
          dataType: this.attributeDetail.dataType,
        };
    }
  }

  private setEnumType() {
    this.isEnumSingleDropDownAttribute = this.enumSingleSelectDropDownConditionalList.findIndex(value => value === (this.activeFilter$.getValue()?.operator)) !== -1;
    this.isEnumMultiDropDownAttribute = this.enumMultiSelectDropDownConditionalList.findIndex(value => value === (this.activeFilter$.getValue()?.operator)) !== -1;
  }

  getResultDisplayName(result: any): string {
    if (!result) {
      return '';
    }

    if (!result.displayName || result.displayName.trim() === '') {
      return result.id;
    }

    if (result.displayName !== result.id) {
      return `${result.displayName} (${result.id})`;
    }

    return result.displayName;
  }

  onEnumSearchChangeInAutoComplete(searchText: string) {
    this.attributeForm.get('filterValue').setValue(searchText);
    this.attributeForm.get('filterValue').markAsDirty();
    this.filterValueOnEdit = searchText;
    this.attributeFilteredEnumValues = this.attributeEnumValues
      .filter((item) => {
        const displayName = this.getResultDisplayName(item);
        return displayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      })
      .sort();
  }

  onEnumSearchChange(searchText: string) {
    this.attributeFilteredEnumValues = this.attributeEnumValues
      .filter((item) => {
        const displayName = this.getResultDisplayName(item);
        return displayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      })
      .sort();
  }

  onEnumSelectionChange(searchText: string) {
    this.attributeForm.get('filterValue').setValue(searchText);
    this.attributeForm.get('filterValue').markAsDirty();
    this.filterValueOnEdit = searchText;
  }

  getSelectedDataSetEnumAttributes() {
    return this.attributeForm.get('filterValue').value.map((enumName: string) => {
      return this.attributeFilteredEnumValues.find(val => val.id === enumName).displayName + '(' + this.attributeFilteredEnumValues.find(val => val.id === enumName).id + ')';
    }).join(', ');
  }

  getEnumValueDescriptor(enumValueArray: any[]): EnumValueDescriptor[] {
    return enumValueArray.map(enumValue => {
      const selectedEnumValue = this.attributeEnumValues.find(attributeEnum => attributeEnum.id === enumValue);
      return {
        enumValue: {
          id: selectedEnumValue.id,
          displayName: selectedEnumValue.displayName
        }
      };
    });
  }
}
