import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { compact, first, map as _map, orderBy, remove } from 'lodash-es';
import { NavTreeNode, NavTreeNodeStatus } from '@epsilon/pcbc-nav-tree';
import { PickerService } from './picker.service';
import { typeToCuikIconRef } from '../utils/type-icon-utils';
import { DetailsModalService } from '../services/details-modal.service';
import { DragDropService } from './../services/drag-drop.service';
import { DataSetType, DataType } from '../enums/data-types';
import { cabAttributeTooltip, cabPathList } from '../audience/audience.utils';
import { DedupeType } from '../models/dedupe-type';
import {
  FEATURE_ATTRIBUTE_SEARCH,
  FEATURE_AUDIENCES,
} from '../utils/feature-utils';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { ActivatedRoute } from '@angular/router';
import { NavTreeNodeComponent } from '@epsilon/pcbc-nav-tree/lib/nav-tree-node/nav-tree-node.component';
import { FeatureService } from '../utils/feature-service';
import {
  TreeProgressiveComponent,
  NotificationComponent,
} from '@epsilon/core-ui';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { selectContext } from '../context/context.reducer';
import { cloneDeep } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'lib-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.sass'],
})
export class PickerComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() contextId: string;
  @Input() dataUniverseId: string;
  @Input() recordLimit = 20;
  @Input() activeDedupeType: DedupeType;
  @Input() showAttributeTypekeyLegend = false;
  @Input() isProductTypeDcdp?: boolean;
  @Input() channelType: string;
  @Input() audienceForm: UntypedFormGroup;
  isActive = false;
  nodes: NavTreeNode[] = [];
  attributeSearchEnabled: boolean;
  audiencesEnabled: boolean;
  searchNodes: NavTreeNode[] = [];
  typeIconConfig = typeToCuikIconRef;
  showSearchTree = false;
  searchText = '';
  showSearchLimit = false;
  loadingNodes = false;
  baseNodes = [];
  attributeSearchDropdownOptions = [
    { label: 'Attributes', value: 'DATA_SET_ATTRIBUTE' },
    { label: 'Definitions', value: 'AUDIENCE_DEFINITION' },
    { label: 'Lists', value: 'AUDIENCE_LIST' },
  ];
  @ViewChild('generalErrorNotification')
  generalErrorNotification: NotificationComponent;

  public attributeSearchFormGroup = new UntypedFormGroup({
    attributeSearchDropdown: new UntypedFormControl({
      value: this.attributeSearchDropdownOptions[0].value,
    }),
    displayName: new UntypedFormControl(''),
  });
  selectedFilter: string;
  audienceDefinitionId: string;
  breadcrumbs = [];

  constructor(
    private pickerService: PickerService,
    public detailsModalService: DetailsModalService,
    public dragdropService: DragDropService,
    private cd: ChangeDetectorRef,
    public audienceBuilderService: AudienceBuilderService,
    private route: ActivatedRoute,
    public featureService: FeatureService,
    public store: Store<AppState>
  ) {}

  ngOnInit() {
    this.attributeSearchFormGroup
      .get('attributeSearchDropdown')
    .valueChanges.pipe(untilDestroyed(this))
    .subscribe((value: any) => {
      this.onSearchFilterChange(value);
    });
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.attributeSearchEnabled = this.featureService.isFeatureEnabled(
          FEATURE_ATTRIBUTE_SEARCH
        );
        this.audiencesEnabled =
          this.featureService.isFeatureEnabled(FEATURE_AUDIENCES);
      });
    if (!this.audiencesEnabled) {
      this.attributeSearchDropdownOptions =
        this.attributeSearchDropdownOptions.filter(
          (dropdown) => dropdown.value !== 'AUDIENCE_LIST'
        );
    }
    this.attributeSearchFormGroup
      .get('attributeSearchDropdown')
      .setValue(this.attributeSearchDropdownOptions[0].value);
    this.audienceDefinitionId =
      this.route.snapshot.paramMap.get('definitionId');

    if (this.isProductTypeDcdp) this.recordLimit = 10;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['activeDedupeType'] &&
      changes['activeDedupeType'].previousValue !==
        changes['activeDedupeType'].currentValue
    ) {
      this.activeDedupeType = changes['activeDedupeType']?.currentValue;
      this.searchText = '';
      this.showSearchLimit = false;
      this.showSearchTree = false;
      this.nodes = [];
      this.searchNodes = [];
      this.fetchRoot();
    }
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  public onNodeSelection() {
    // onNodeSelection Event Handler when node Select
  }

  disableClick(event: any) {
    event.active = false;
  }

  fetchRoot() {
    if (this.activeDedupeType) {
      if (this.currentNode?.cabId === 'cab:datasetcat:datasetcat') {
        this.recordLimit = 1000;
      }
      else if (
        this.currentNode?.cabId === 'cab:auddefcat:auddefcat' ||
        this.currentNode?.cabId === 'cab:audlistcat:audlistcat'
      ) {
        this.recordLimit = 20;
        if (this.isProductTypeDcdp) this.recordLimit = 10;
      }
      this.pickerService
        .fetchAttributes(
          this.contextId,
          this.dataUniverseId,
          this.activeDedupeType,
          this.recordLimit,
          null,
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            const nodes = [];
            const rootNodes = response.results;
            this.searchId = response?.searchId ?? null;
            const fixedOptions = new Map();
            fixedOptions.set(
              'Audience Definitions',
              this.isProductTypeDcdp ? 'Definitions' : 'Audience Definitions'
            );
            fixedOptions.set(
              'Audience Lists',
              this.isProductTypeDcdp ? 'Lists' : 'Audience Lists'
            );
            fixedOptions.set(
              'Marketing Catalog',
              this.isProductTypeDcdp
                ? 'Attributes from Data Objects'
                : 'Marketing Catalog'
            );
            const fixedNodes = compact(
              _map(fixedOptions.keys(), (nodeName) =>
                first(
                  remove(
                    rootNodes,
                    (node: NavTreeNode) => node['displayName'] === nodeName
                  )
                )
              )
            );
            if (this.isProductTypeDcdp) {
              rootNodes.forEach((node) => {
                if (fixedOptions.has(node.displayName)) {
                  node.name = fixedOptions.get(node.name);
                  node.displayName = fixedOptions.get(node.displayName);
                }
              });
            }
            let orderedNodes = [
              ...fixedNodes,
              ...orderBy(rootNodes, 'displayName'),
            ];
            if (!this.audiencesEnabled) {
              orderedNodes = orderedNodes.filter(
                (node) => node.displayName !== 'Lists'
              );
            }
            orderedNodes.forEach((n) => nodes.push(n));
            this.rootNodes = [...nodes];
          },
          (err) => {
            this.generalErrorNotification.show();
            console.log(err)
          }
        );
    }
  }

  fetchChildren(navTreeNode: NavTreeNodeComponent) {
    this.generalErrorNotification.hide();
    const parentNode = navTreeNode.node;
    let startIndex = parentNode.children?.length;
    parentNode.status = NavTreeNodeStatus.LOADING;
    navTreeNode.node = { ...parentNode };
    navTreeNode.refresh();
    if (startIndex > 0 && parentNode['dataSetType'] === DataSetType.DATA_SET) {
      startIndex = parentNode.children.filter((node) => !node['leaf']).length;
    }
    if (parentNode['cabId'] === 'cab:datasetcat:datasetcat') {
      this.recordLimit = 1000;
    }
    let searchId = null;
    if (
      navTreeNode['cabId'] === 'cab:auddefcat:auddefcat' ||
      navTreeNode['cabId'] === 'cab:audlistcat:audlistcat'
    ) {
      this.recordLimit = 20;
      if (this.isProductTypeDcdp) this.recordLimit = 10;
      searchId = this.searchId;
    }
    this.pickerService
      .fetchAttributes(
        this.contextId,
        this.dataUniverseId,
        this.activeDedupeType,
        this.recordLimit,
        parentNode['path'],
        parentNode['cabId'],
        startIndex,
        searchId
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.searchId = response?.searchId ?? null;
          const childNodes = response.results.filter(
            (node) => node.id !== this.audienceDefinitionId
          );
          if (startIndex > 0) {
            let existingChildNodes = parentNode.children;
            existingChildNodes = existingChildNodes.concat(childNodes);
            parentNode.children = [];
            orderBy(existingChildNodes, ['leaf', 'displayName']).forEach(
              (node) => parentNode.children.push(node)
            );
          } else {
            orderBy(childNodes, ['leaf', 'displayName']).forEach((node) =>
              parentNode.children.push(node)
            );
          }
          parentNode.status = response.hasMore
            ? NavTreeNodeStatus.PARTIAL
            : NavTreeNodeStatus.COMPLETE;
          navTreeNode.node = { ...parentNode };
          navTreeNode.refresh();
        },
        (err) => {
          this.generalErrorNotification.show();
          console.log(err)
        }
      );
  }

  rootNodes = [];
  loadMore = false;
  isLoading = false;
  searchId = null;

  @ViewChild('treeProgressiveRef', { static: false })
  treeProgressiveRef: TreeProgressiveComponent;

  currentNode;

  fetchChildrenNew(navTreeNode, breadcrumbEvent = false) {
    this.generalErrorNotification.hide();
    this.loadingNodes = true;
    this.searchNodes = [];
    this.showSearchTree = false;
    let startIndex = breadcrumbEvent ? 0 : navTreeNode?.children?.length ?? 0;
    if (startIndex > 0 && navTreeNode['dataSetType'] === DataSetType.DATA_SET) {
      startIndex = navTreeNode?.children.filter((node) => !node['leaf']).length;
    }
    this.recordLimit = 1000;

    let searchId = null;
    if (
      navTreeNode['cabId'] === 'cab:auddefcat:auddefcat' ||
      navTreeNode['cabId'] === 'cab:audlistcat:audlistcat'
    ) {
      this.recordLimit = 20;
      if (this.isProductTypeDcdp) this.recordLimit = 10;
      searchId = this.searchId;
    }
    this.pickerService
      .fetchAttributes(
        this.contextId,
        this.dataUniverseId,
        this.activeDedupeType,
        this.recordLimit,
        navTreeNode['path'],
        navTreeNode['cabId'],
        startIndex,
        searchId
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any) => {
          if (!breadcrumbEvent && !this.loadMore) {
            this.breadcrumbs.push(navTreeNode);
          }
          const childNodes = response['results'].filter(
            (node) => node.id !== this.audienceDefinitionId
          );

          let orderedNodes = [];
          if (startIndex > 0) {
            const existingChildNodes = [...cloneDeep(navTreeNode.children), ...cloneDeep(childNodes)];
            navTreeNode.children = [];
            orderedNodes = existingChildNodes;
            if (
              !(navTreeNode['cabId'] === 'cab:auddefcat:auddefcat' ||
                navTreeNode['cabId'] === 'cab:audlistcat:audlistcat')
            ) {
              orderedNodes = orderBy(existingChildNodes, ['leaf', 'displayName']);
            }
            orderedNodes.forEach(
              (node) => {
                navTreeNode.children.push(node);
              }
            );
          } else {
            navTreeNode.children = [];
            orderedNodes = childNodes;
            if (
              !(navTreeNode['cabId'] === 'cab:auddefcat:auddefcat' ||
                navTreeNode['cabId'] === 'cab:audlistcat:audlistcat')
            ) {
              orderedNodes = orderBy(childNodes, ['leaf', 'displayName']);
            }
            orderedNodes.forEach((node) =>
              navTreeNode.children.push(node)
            );
          }
          this.loadMore = response?.hasMore ?? false;
          this.loadingNodes = false;
          this.currentNode = navTreeNode;
          this.rootNodes = cloneDeep(navTreeNode.children ?? []);
          this.searchId = response?.searchId ?? null;
        },
        (error) => {
          this.loadingNodes = false;
          this.generalErrorNotification.show();
          console.log(error)
        }
      );
  }

  navigateToLink(bIndex) {
    this.searchNodes = [];
    this.showSearchTree = false;
    this.searchId = null;
    if (bIndex !== null ) {
      this.fetchChildrenNew(this.breadcrumbs[bIndex], true);
      this.breadcrumbs = this.breadcrumbs.slice(0, bIndex + 1);
    } else {
      this.breadcrumbs = [];
      this.loadMore = false;
      this.currentNode = null;
      this.fetchRoot();
    }
    this.searchText = '';
    this.showSearchLimit = false;
  }

  isCabAttribute(node: NavTreeNode) {
    const dataType = node['dataType']?.toLowerCase();
    return (
      dataType === DataType.AUDIENCE_LIST ||
      dataType === DataType.AUDIENCE_DEFINITION
    );
  }

  cabAttributeTooltip(node: NavTreeNode): string {
    const dataType = node['dataType']?.toLowerCase();
    if (this.isProductTypeDcdp) {
      return this.audienceBuilderService.getAudienceDisplayName(
        this.audienceForm.get('dedupeType').value,
        this.channelType
      );
    } else {
      return cabAttributeTooltip(dataType);
    }
  }
  isRegularSearch = false;
  attributeSearch(isLoadMore?: boolean) {
    this.isLoading = true;
    let startIndex = 0;
    if (isLoadMore) {
      startIndex = this.searchNodes?.length ?? 0;
    } else { 
      this.searchNodes = [];
    }
    if (this.searchText.trim().length < 3) {
      this.showSearchLimit = true;
      return;
    }
    this.showSearchLimit = false;
    if (!Object.keys(cabPathList).includes(this.currentNode['cabId'])) {
      this.selectedFilter =
        this.attributeSearchDropdownOptions.find(
          (attribute) => attribute.label === this.breadcrumbs[1]?.displayName
        )?.value ?? this.attributeSearchDropdownOptions[0]?.value;
      if (this.selectedFilter === 'DATA_SET_ATTRIBUTE') {
        if (!this.loadMore && this.rootNodes.length <= 1000) {
          this.searchNodes = this.rootNodes.filter((node) =>
            node.displayName
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          );
          this.showSearchTree = true;
          this.isRegularSearch = true;
          this.isLoading = false;
          return;
        }
      } else {
        this.isRegularSearch = false;
      }
    }

    if (Object.keys(cabPathList).includes(this.currentNode['cabId'])) {
      this.selectedFilter = cabPathList[this.currentNode['cabId']];
    }

    let searchId = null;
    if (this.currentNode?.cabId === 'cab:datasetcat:datasetcat') {
      this.recordLimit = 1000;
    }
    else if (
      this.currentNode?.cabId === 'cab:auddefcat:auddefcat' ||
      this.currentNode?.cabId === 'cab:audlistcat:audlistcat'
    ) {
      searchId = isLoadMore ? this.searchId : null;
      this.recordLimit = 20;
    }
    this.pickerService
      .searchAttributes(
        this.contextId,
        this.dataUniverseId,
        this.activeDedupeType,
        this.searchText,
        this.selectedFilter !== 'DATA_SET_ATTRIBUTE'
        ? this.selectedFilter
        : 'DATA_SET',
        this.currentNode?.cabId,
        this.rootNodes.length > 1000 && this.loadMore
          ? this.currentNode['path']
          : null,
        this.recordLimit,
        startIndex,
        searchId
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          if (isLoadMore) {
            this.searchNodes = [...cloneDeep(this.searchNodes), ...cloneDeep(response.results)];
          } else {
            this.searchNodes = cloneDeep(response.results);
          }
          const childNodes = this.searchNodes.filter(
            (node) => node.id !== this.audienceDefinitionId
          );
          if (
            !(this.currentNode?.cabId === 'cab:auddefcat:auddefcat' ||
            this.currentNode?.cabId === 'cab:audlistcat:audlistcat')
          ) {
            this.searchNodes = orderBy(childNodes, ['leaf', 'displayName']);
          } else {
            this.searchNodes = cloneDeep(childNodes);
          }
          this.showSearchTree = true;
          this.isLoading = false;
          this.searchId = response?.searchId ?? null;
          this.loadMore = response?.hasMore ?? false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      );
  }

  clearAttributeSearch() {
    this.showSearchTree = false;
    this.searchNodes = [];
    this.isRegularSearch = false;
    this.searchText = '';
    this.showSearchLimit = false;
    this.searchId = null;
    if (this.currentNode) {
      this.currentNode.children = [];
      this.fetchChildrenNew(this.currentNode, true);
    }
  }

  getJoinNodes(path: NavTreeNode) {
    return path['filter']((itemNode) => itemNode.childNodeRelationInfo);
  }

  public onSearchFilterChange(selectedOption) {
    this.selectedFilter = selectedOption;
    this.showSearchTree = false;
  }
}
