import { Injectable, OnDestroy } from '@angular/core';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import {BehaviorSubject, combineLatest as observableCombineLatest, Observable} from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get as _get, keyBy } from 'lodash-es';
import { fetchResource } from '../utils/fetch-state';
import * as actions from './audience-builder.actions';
import {
  AudienceDefinitionGenerateAIRating,
  AudienceDefinitionGenerateAIResponse,
  AudienceRuleGroup,
  BuilderAudience, GenerateAudienceQueryFromTextRequest,
  PrivacyCodeClassifications,
  PrivacyCodeClassificationsPayload,
  newAudience,
  AlternateKeyPayload,
  AlternateKeyResponse
} from './audience-builder.models';
import { selectPrebuiltAudience, selectDataTypeOperators } from './audience-builder.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import {
  DataTypeOperator,
  transformDataTypeOperators,
} from '../models/data-type-operator';
import { DedupeType } from '../models/dedupe-type';
import {
  selectActiveDataUniverseId,
  selectDedupeTypes,
} from '../data-universe/data-universe.reducer';
import { isDefined } from '../utils/utils';
import { DataType } from '../enums/data-types';
import { Audience, AudienceDefinition } from '../audience/audience.models';
import {
  convertAudienceDefinitionToBuilder,
  expressionsHaveValues,
  handleEmptyGroups,
} from '../audience/audience.utils';
import { UpdateTenantRedirectStatus } from '../tenant/tenant.actions';
import { selectRedirectTenantStatus } from '../tenant/tenant.reducer';
import { CabConstants } from '../cab.constants';
import { UtilsService } from '../utils/utilservice';
import { HttpClientService } from '../services/http-client.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AudienceBuilderService implements OnDestroy {
  audience$: Observable<BuilderAudience> = this.store
    .select(selectPrebuiltAudience)
    .pipe(map((prebuiltAudience) => prebuiltAudience || newAudience()));
  public audience: BuilderAudience;
  public rules: AudienceRuleGroup[][];
  dataTypeOperators$: Observable<DataTypeOperator[]> = this.store.select(selectDataTypeOperators);
  dataTypeOperators: DataTypeOperator[];
  activeDataUniverseId$ = this.store
    .select(selectActiveDataUniverseId)
    .pipe(
      filter(
        (dataUniverseId) =>
          isDefined(dataUniverseId) && dataUniverseId !== 'all'
      )
    );
  dedupeTypes$: Observable<DedupeType[]> = this.activeDataUniverseId$.pipe(
    debounceTime(100),
    switchMap((dataUniverseId) =>
      this.store.select(selectDedupeTypes(dataUniverseId))
    )
  );
  definitionHasAttribute$ = new BehaviorSubject<boolean>(null);
  allExpressionsHaveValues$ = new BehaviorSubject<boolean>(null);
  audienceBuilderUpdatedManually$ = new BehaviorSubject<boolean>(null);
  audienceBuilderCriteriaManually$ = new BehaviorSubject<boolean>(null);
  audienceEditSavedSuccess$ = new  BehaviorSubject<boolean>(false);
  isElementManuallyDropped: boolean;
  isEditingInProgress = false;
  public identityTypes: DedupeType[];
  public attributeFromFilterValueErrorMap =  {};

  constructor(
    private http: HttpClientService,
    private httpService: CabHttpService,
    private actions$: Actions,
    private store: Store<AppState>,
    private utilsService: UtilsService
  ) {
    this.initAudienceData();

    this.dataTypeOperators$
      .pipe(untilDestroyed(this))
      .subscribe(
        (dataTypeOperators) => (this.dataTypeOperators = dataTypeOperators)
      );

    this.audienceBuilderUpdatedManually$.subscribe(val => {
      this.store.dispatch(new UpdateTenantRedirectStatus(!val));
    });

    this.store.select(selectRedirectTenantStatus).subscribe(val => {
      this.isEditingInProgress = !val;
    });

    observableCombineLatest([
      this.dedupeTypes$.pipe(filter(isDefined))
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([dedupeTypes]) => {
        if (dedupeTypes.length) {
          this.identityTypes = dedupeTypes;
        }
      });
  }

  ngOnDestroy() {
    this.store.dispatch(new actions.SetPrebuiltAudience(null));
  }

  public initAudienceData(){
    this.audience$.pipe(untilDestroyed(this)).subscribe((audience) => {
      this.audience = audience;
      this.rules = [
        this.audience.includeConditions || [],
        this.audience.excludeConditions || [],
      ];
    });
  }

  public checkForAttribute() {
    const includeConditions = handleEmptyGroups(
      this.audience.includeConditions,
      true,
      'Include'
    );
    const excludeConditions = handleEmptyGroups(
      this.audience.excludeConditions,
      true,
      'Exclude'
    );
    const hasAttribute = [includeConditions, excludeConditions].some(
      (condition) => condition.some((cond) => cond.group?.length > 0)
    );

    this.definitionHasAttribute$.next(hasAttribute);
  }

  checkForAttributeValues() {
    const includeConditions = handleEmptyGroups(
      this.audience.includeConditions,
      true,
      'Include'
    );
    const excludeConditions = handleEmptyGroups(
      this.audience.excludeConditions,
      true,
      'Exclude'
    );

    const values = [includeConditions, excludeConditions].every((ruleGroups) =>
      expressionsHaveValues(ruleGroups, this.dataTypeOperators)
    );

    this.allExpressionsHaveValues$.next(values);
  }

  fetchDataTypeOperators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchDataTypeOperators.type),
      fetchResource((action) =>
        this.fetchDataTypeOperators(
          action.contextId,
          action.dataUniverseId
        ).pipe(map((operators) => new actions.LoadDataTypeOperators(operators)))
      )
    )
  );

  setPrebuiltAudience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SetPrebuiltAudience.type),
      mergeMap((action: any) => {
        if (
          action &&
          action.audience &&
          action.contextId &&
          action.dataType &&
          action.shouldFetchEditDetails
        ) {
          return [
            new actions.FetchAttributeDetails(
              action.audience,
              action.contextId,
              action.dataType
            ),
          ];
        } else if (
          action &&
          action.audience &&
          !action.shouldFetchEditDetails
        ) {
          return [new actions.LoadPrebuiltAudience(action.audience)];
        } else {
          // reset to null
          return [
            new actions.LoadAttributeDetails({}),
            new actions.LoadPrebuiltAudience(null),
          ];
        }
      })
    )
  );

  fetchAttributeDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FetchAttributeDetails.type),
      fetchResource((action: any) => {
        const { id, dataUniverseId } = action.audience;
        return this.fetchCabAttributeDetails(
          action.dataType,
          action.contextId,
          dataUniverseId,
          id
        ).pipe(
          switchMap((res: any) => {
            const { audienceDefinition, audienceList, nodes, isUsed } = _get(
              res,
              'entity'
            );

            let attribute;
            if (audienceDefinition) {
              attribute = convertAudienceDefinitionToBuilder(
                new AudienceDefinition(
                  this.utilsService.isMocked() ? audienceDefinition[0] : audienceDefinition
                ),
                true
              );
              attribute = { ...attribute, isUsed };
            } else if (audienceList) {
              attribute = convertAudienceDefinitionToBuilder(
                new Audience(this.utilsService.isMocked() ? audienceList[0] : audienceList),
                true
              );
            }

            return [
              new actions.LoadAttributeDetails(keyBy(nodes, 'cabId')),
              new actions.LoadPrebuiltAudience(attribute),
            ];
          })
        );
      })
    )
  );

  fetchDataTypeOperators(
    contextId: string,
    dataUniverseId: string
  ): Observable<DataTypeOperator[]> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http
      .get<DataTypeOperator[]>(this.dataTypeOperatorsUrl(dataUniverseId), {
        headers,
      })
      .pipe(map((res) => transformDataTypeOperators(res)));
  }

  dataTypeOperatorsUrl(dataUniverseId: string): string {
    if (this.utilsService.isMocked()) {
      return this.httpService.apiUrl(
        ContextType.DISCOVERY,
        `/id_analytics_api/cab/v1/data-universe/${dataUniverseId}/data-type-metadata`
      );
    }
    return this.httpService.apiUrl(
      ContextType.CAB,
      `/cab/v1/data-universe/${dataUniverseId}/data-type-metadata?includeSupportedOnly=true`
    );
  }

  fetchCabAttributeDetails(
    attributeType: DataType.AUDIENCE_DEFINITION | DataType.AUDIENCE_LIST | DataType.MONITOR,
    contextId: string,
    dataUniverseId: string,
    attributeId: string
  ) {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] =  contextId;
    return this.http.get(
      this.attributeDetailsUrl(attributeType, dataUniverseId, attributeId),
      { headers }
    );
  }

  fetchCabDependentDefinitions(
    contextId: string,
    audienceDefinitionId: string
  ) {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] =  contextId;
    return this.http.get(
      this.dependentDefinitionsUrl(audienceDefinitionId),
      { headers }
    );
  }

  fetchAlternateKeys(
    alternateKeyPayload: AlternateKeyPayload
  ): Observable<AlternateKeyResponse> {
    const url = '/cab/v1/audience-builder/search-enum-values';
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = alternateKeyPayload.cabContextId;
    return this.http
      .post<AlternateKeyResponse>(
        this.httpService.apiUrl(ContextType.CAB, url),
        alternateKeyPayload,
        { headers }
      )
      .pipe(
        map((res: AlternateKeyResponse) => {
          return res;
        })
      );
  }

  generateAudienceDefinitionFromText(generateFromTextRequest: GenerateAudienceQueryFromTextRequest): Observable<AudienceDefinitionGenerateAIResponse> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] =  generateFromTextRequest.cabContextId;
    const url = '/cab/v1/audience-builder/generate-query-from-text';
    return this.http.post(this.httpService.apiUrl(ContextType.CAB, url), generateFromTextRequest, { headers }) .pipe(
      map(res => res)) as Observable<AudienceDefinitionGenerateAIResponse>;
  }

  privacyCodeClassifications(privacyCodeClassificationsRequest: PrivacyCodeClassificationsPayload): Observable<PrivacyCodeClassifications> {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] =  privacyCodeClassificationsRequest.cabContextId;
    const url = '/cab/v1/audience-builder/privacy-code-classifications';
    return this.http.post(this.httpService.apiUrl(ContextType.CAB, url), privacyCodeClassificationsRequest, { headers }) .pipe(
      map(res => res)) as Observable<PrivacyCodeClassifications>;
  }

  getDetailsURLType(type: string): string{
    switch(type){
      case DataType.AUDIENCE_DEFINITION:
        return 'audience-definition';
      case DataType.AUDIENCE_LIST:
        return 'audience-list';
      case DataType.MONITOR:
        return 'campaign-extract';
      default:
        return'';
    }
  }

  attributeDetailsUrl(type, dataUniverseId, definitionId): string {
    const typeUrl = this.getDetailsURLType(type);
    const url = `/cab/v1/audience-builder/${dataUniverseId}/${typeUrl}/${definitionId}/edit-details`;
    if (this.utilsService.isMocked()) {
      return this.httpService.apiUrl(
        ContextType.DISCOVERY,
        `/id_analytics_api${url}`
      );
    }
    return this.httpService.apiUrl(ContextType.CAB, url);
  }

  dependentDefinitionsUrl(audienceDefinitionId): string {
    const url = `/cab/v1/audience-definition/${audienceDefinitionId}/dependentDefinitions`;
    return this.httpService.apiUrl(ContextType.CAB, url);
  }

  clearDatatTypeOperators() {
    this.store.dispatch(new actions.ClearDataTypeOperators());
  }

  getAudienceDisplayName(identityType: string, channelType: string, alternateKeyType = ''): string {
    if (channelType === 'PAID') {
      return 'CORE IDs';
    } else if (channelType === 'OWNED') {
      const dedupeType = this.identityTypes?.filter(dedupe => dedupe.identityType === identityType)[0];
      if (dedupeType?.productIdentityInfo?.goldenProfile) {
        if (
          dedupeType?.identityType == 'GoldenEmail' ||
          dedupeType?.identityType == 'GoldenAlternateKey'
        ) {
          return `Golden Profiles Dedupe By ${dedupeType?.name}`;
        }
        return 'Golden Profiles';
      } else if (dedupeType?.identityType === 'Prospect') {
        return 'Prospects';
      } else if (
        dedupeType?.identityType === 'ProspectEmail' ||
        dedupeType?.identityType === 'ProspectCoreId'
      ) {
        return `Prospects Dedupe By Client-Specific ${dedupeType?.name}`;
      } else if (dedupeType?.productIdentityInfo?.profile) {
        return 'Profiles';
      } else {
        return `Profiles Dedupe By ${dedupeType?.name} ${alternateKeyType}`;
      }
    } else {
      return identityType;
    }
  }
  
  getEditingInProgress() {
    return this.isEditingInProgress;
  }

  validateDefinitionName(definitionName: string, contextId: string) {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    const url = `/cab/v1/audience-builder/AUDIENCE_DEFINITION/by-display-name/${definitionName}`;
    return this.http.get(this.httpService.apiUrl(ContextType.CAB, url), { headers });
  }

  generateAudienceDefinitionGenAIQueryRating(generateFromTextRequest: AudienceDefinitionGenerateAIRating) {
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = generateFromTextRequest.cabContextId;
    const url = '/cab/v1/audience-builder/gen-ai/query-rating';
    return this.http.post(this.httpService.apiUrl(ContextType.CAB, url), generateFromTextRequest, { headers })
      .pipe(
        map(res => res)
      ) as Observable<AudienceDefinitionGenerateAIRating>;
  }

  getGenAIDatasetCategories (contextId) {
    const url = '/cab/v1/audience-builder/gen-ai/dataset-categories';
    const headers = {};
    headers[CabConstants.CAB_CONTEXT_HEADER] = contextId;
    return this.http.get(this.httpService.apiUrl(ContextType.CAB, url), { headers });
  }  
}
