import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { AudienceStatus } from '../../audience/audience.models';
import { map, startWith, pairwise, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CheckboxMultipleSelectionEvent } from '@epsilon/core-ui';
import {MonitorTableSubType, TableType} from '../audience-overview.models';
import {
  DEFINITION_TXT,
  MONITOR_HISTORY_TXT,
  UI_STATUS_COMPLETE,
  UI_STATUS_ERROR,
  UI_STATUS_PROCESSING
} from '../../utils/utils';

@UntilDestroy()
@Injectable()
export class TableFilterService {
  public headerCheckboxIndeterminate: boolean;
  public statusButtonLabel$: Observable<string>;
  public jobTypeButtonLabel$: Observable<string>;
  public scheduleTypeButtonLabel$: Observable<string>;
  public dateTypeButtonLabel$: Observable<string>;
  public audienceDisplayNameTypesButtonLabel$:  Observable<string>;
  public tableType$ = new BehaviorSubject<TableType>(DEFINITION_TXT);
  public monitorTableSubType$ = new BehaviorSubject<MonitorTableSubType>(MONITOR_HISTORY_TXT);
  public statuses$ = new BehaviorSubject<string[]>([]);
  public audienceDisplayNameTypes$ = new BehaviorSubject<{name: string; value: string[]}[]>([]);
  public jobTypes$ = new BehaviorSubject<string[]>([]);
  public scheduleTypes$ = new BehaviorSubject<string[]>([]);
  public dateTypes$ = new BehaviorSubject<string>('');
  public sendToTargetType$ = new BehaviorSubject<string>('paid');
  public rowSelected = 0;
  checkedAllStatus = false;

  public tags$ = new BehaviorSubject<string[]>([]);
  public searchId$ = new BehaviorSubject<string>('');
  public searchSavedFilter$ = new BehaviorSubject<any>("reset");


  private statuses: AudienceStatus[] = [UI_STATUS_PROCESSING, UI_STATUS_COMPLETE, UI_STATUS_ERROR];
  private jobTypes: any[] = [{name: 'Export Definition', value: 'AudienceDefinition'}, {name: 'Export List', value: 'AudienceList'}];
  private scheduleTypes: any[] = [{name: 'Export Definition', value: 'EXTRACT_FROM_DEFINITION'}, {name: 'Create List', value: 'AUDIENCE_LIST'}];
  public audienceDisplayNameTypes = [{
    name: 'CORE IDs',
    value: ['DigitalCoreId']
  },
  {
    name: 'Golden Profiles',
    value: ['GoldenAlternateKey', 'GoldenEmail', 'GoldenProfile']
  },
  {
    name: 'Profiles',
    value: ['AlternateKey', 'Profile', 'CsCoreId', 'Email']
  },
  {
    name: 'Prospects',
    value: ['Prospect', 'ProspectEmail', 'ProspectCoreId']
  }];

  private _formGroup = this.formBuilder.group({
    headerCheckbox: false,
    rows: this.formBuilder.array([]),
    statuses: this.formBuilder.array(this.statuses.map(() => false)),
    jobTypes: this.formBuilder.array(this.jobTypes.map(() => false)),
    scheduleTypes: this.formBuilder.array(this.scheduleTypes.map(() => false)),
    owner: false,
    search: '',
    audienceDisplayNameTypes: this.formBuilder.array(this.audienceDisplayNameTypes.map(() => false))
  });

  constructor(public formBuilder: UntypedFormBuilder) {
    this.formGroup.valueChanges
      .pipe(
        startWith(() => this.formGroup.value),
        pairwise(),
        filter(
          ([val1, val2]: [any, any]): boolean =>
            val1.rows !== val2.rows && val2.rows && val2.rows.length > 0
        ),
        map(([val2]: [any, any]): boolean[] => val2.rows),
        untilDestroyed(this)
      )
      .subscribe((rows: boolean[]) => {
        const everyTrue = rows?.every((i) => i) && rows.length > 0;
        this.formGroup
          .get('headerCheckbox')
          .setValue(everyTrue, { emitEvent: false });
        this.headerCheckboxIndeterminate = !everyTrue && rows?.some((i) => i);
      });

    this.formGroup
      .get('headerCheckbox')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        const rowsValue = this.rowsControl.controls.map(() => value);
        this.rowsControl.setValue(rowsValue);
      });

    this.statusButtonLabel$ = this.statuses$.pipe(
      map((statuses: AudienceStatus[]) => {
        if (statuses.length === 1) {
          return statuses[0];
        } else if (
          !statuses ||
          statuses.length === 0 ||
          statuses.length === this.statuses.length
        ) {
          return 'All';
        } else {
          return `${statuses.length}`;
        }
      })
    );

    this.audienceDisplayNameTypesButtonLabel$ = this.audienceDisplayNameTypes$.pipe(
      map((audienceDisplayNameType) => {
        if (audienceDisplayNameType.length === 1) {
          return audienceDisplayNameType[0].name;
        } else if (
          !audienceDisplayNameType ||
          audienceDisplayNameType.length === 0 ||
          audienceDisplayNameType.length === this.audienceDisplayNameTypes.length
        ) {
          return 'All';
        } else {
          return `${audienceDisplayNameType.length}`;
        }
      })
    );

    this.jobTypeButtonLabel$ = this.jobTypes$.pipe(
      map((jobTypes: any[]) => {
        if (jobTypes.length === 1) {
          return jobTypes[0].name;
        } else if (
          !jobTypes ||
          jobTypes.length === 0 ||
          jobTypes.length === this.jobTypes.length
        ) {
          return 'All';
        } else {
          return `${jobTypes.length}`;
        }
      })
    );

    this.scheduleTypeButtonLabel$ = this.scheduleTypes$.pipe(
      map((scheduleTypes: any[]) => {
        if (scheduleTypes.length === 1) {
          return scheduleTypes[0].name;
        } else if (
          !scheduleTypes ||
          scheduleTypes.length === 0 ||
          scheduleTypes.length === this.scheduleTypes.length
        ) {
          return 'All';
        } else {
          return `${scheduleTypes.length}`;
        }
      })
    );

    // reset form controls on table type change
    this.tableType$.subscribe(() => {
      setTimeout(() => {
        this.statuses$.next([]);
        this.jobTypes$.next([]);
        this.scheduleTypes$.next([]);
        this.dateTypes$.next('');
        this.formGroup.get('owner').reset(false);
        this.formGroup.get('search').reset('');
        this.sendToTargetType$.next('paid');
      }, 10);
    });

    // reset form controls on monitor table subtype change
    this.monitorTableSubType$.subscribe(() => {
      this.statuses$.next([]);
      this.jobTypes$.next([]);
      this.scheduleTypes$.next([]);
      this.dateTypes$.next('');
      this.formGroup.get('owner').reset(false);
      this.formGroup.get('search').reset('');
    });
  }

  public get formGroup() {
    return this._formGroup;
  }

  public get rowsControl(): UntypedFormArray {
    return this.formGroup.get('rows') as UntypedFormArray;
  }

  public get hasSelectedRows(): boolean {
    return this.rowsControl.value.some((i: boolean) => i);
  }

  public onMultipleSelected(event: CheckboxMultipleSelectionEvent): void {
    this.rowsControl.controls.forEach((control: UntypedFormControl, i: number) => {
      if (i >= event.indexes[0] && i <= event.indexes[1]) {
        control.setValue(event.checked);
      }
    });
  }

  public onStatusApplyClick() {
    const value = this.formGroup.get('statuses').value;
    const statuses = this.statuses.filter(
      (_: string, index: number) => value[index]
    );
    this.statuses$.next(statuses);
  }

  public onAudienceDisplayNameTypeApplyClick() {
    const value = this.formGroup.get('audienceDisplayNameTypes').value;
    const audienceDisplayNameTypes = this.audienceDisplayNameTypes.filter(
      (audienceDisplayNameType, index: number) => value[index]
    );
    this.audienceDisplayNameTypes$.next(audienceDisplayNameTypes);
  }

  public onJobTypeApplyClick() {
    const value = this.formGroup.get('jobTypes').value;
    const jobTypes = this.jobTypes.filter(
      (_: string, index: number) => value[index]
    );
    this.jobTypes$.next(jobTypes);
  }

  public onScheduleTypeApplyClick() {
    const value = this.formGroup.get('scheduleTypes').value;
    const scheduleTypes = this.scheduleTypes.filter(
      (_: string, index: number) => value[index]
    );
    this.scheduleTypes$.next(scheduleTypes);
  }

  onTagsApplyClick(value) {
    this.tags$.next(value);
  }
  public onDateFilterClick(filterDate: string) {
    this.dateTypes$.next(filterDate);
  }

  public onTargetTypeClick(type: string) {
    this.sendToTargetType$.next(type);
  }

  // required for multi-select checkbox delete action
  public resetFormRows(items: any[]) {
    this.formGroup.removeControl('rows');
    const newRows = items.map(() => new UntypedFormControl(this.checkedAllStatus));
    const newFormArray = new UntypedFormArray(newRows);
    this.formGroup.addControl('rows', newFormArray);
  }

  // save table filter session state
  public setSessionState(
    key: string,
    scrollSortOption: string,
    scrollSortDirection: string
  ) {
    const value = this.getSessionState();
    value[key] = { scrollSortOption, scrollSortDirection };
    sessionStorage.setItem('overviewTable', JSON.stringify(value));
  }

  // get table filter session state, if any
  public getSessionState() {
    const overviewTable = sessionStorage.getItem('overviewTable');
    if (overviewTable) {
      try {
        return JSON.parse(overviewTable);
      } catch (err) {
        console.log(
          'overview table session storage data cannot be parsed',
          err
        );
      }
    }
    return {};
  }
}
