import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  AssetSearchFilterCondition,
  AssetSearchRequest,
  AssetSearchResponse,
  AssetService,
} from '@epsilon-cdp/pcm-common-lib';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import {
  selectActiveDataUniverse,
  selectDataUniverses,
} from '../../../data-universe/data-universe.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { AUDIENCE_AI_DAILOG_TXT, BUILDER_TXT, DEFINITION_TXT, isDefined, showToastNotification } from '../../../utils/utils';
import {
  BehaviorSubject,
  Observable,
  auditTime,
  filter,
  of,
  pairwise,
  startWith,
  take,
  tap,
} from 'rxjs';
import { selectTenantContext } from '../../../tenant/tenant.reducer';
import { TableFilterService } from '../table-filter.service';
import { selectContext } from '../../../context/context.reducer';
import { FeatureService } from '../../../utils/feature-service';
import {
  FEATURE_AUDIENCES,
  FEATURE_EXPORT_CAMPAIGN_EXTRACTS,
  FEATURE_SCHEDULING,
} from '../../../utils/feature-utils';
import { AudienceBuilderService } from '../../../audience-builder/audience-builder.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UtilsService } from '../../../utils/utilservice';
import { FeatureAccessService } from '../../../feature-access/feature.access.service';
import { Action } from '../../../feature-access/feature.access.action';
import { Audience, AudienceDefinition } from '../../../audience/audience.models';
import { getAudienceDedupeType } from '../../../audience/audience.utils';
import { first, reject } from 'lodash-es';
import { CabConstants } from '../../../cab.constants';
import {
  DestroyAudienceDefinition,
  DestroyAudienceDefinitions,
  LoadAudienceForCreateList,
} from '../../../audience/audience.actions';
import { ModalComponent } from '@epsilon/core-ui';
import { fetchOutcome } from '../../../utils/fetch-state';
import { Actions } from '@ngrx/effects';
import { AudienceService } from '../../../audience/audience.service';
import { LoadingService } from '../../../services/loading.service';
import moment from 'moment';
import { CONTEXT_AUDIENCE_DEFINITION_AUDIENCE_AI } from '../../../utils/cab-context-feature-utils';

@UntilDestroy()
@Component({
  selector: 'lib-assets-definitions-table',
  templateUrl: './assets-definitions-table.component.html',
  styleUrls: ['./assets-definitions-table.component.sass'],
})
export class AssetsDefinitionsTableComponent implements OnInit {
  @ViewChild('deleteActionOneModal') public deleteActionOneModal;
  @ViewChild('destroySuccess', { static: true }) public destroySuccess;
  @ViewChild('destroyError', { static: true }) public destroyError;
  @ViewChild('successCreatingList', { static: true }) public successCreatingList;
  PAGE_LIMIT = 25;
  formGroup: UntypedFormGroup;
  scrollSortOption = 'lastModifiedDate';
  scrollSortDirection: 'ASC' | 'DESC' = 'DESC';

  filteredDefinitions = [];
  paginationPayload: AssetSearchRequest = {
    searchName: '',
    searchId: '',
    filter: [
      {
        attribute: 'type',
        values: ['AUDIENCE_DEFINITION'],
        condition: AssetSearchFilterCondition.IN,
      },
    ],
    limit: this.PAGE_LIMIT,
    offset: 0,
    sort: ['-data.lastModifiedDate'],
  };
  hasMore = false;
  productType: string;
  schedulingEnabled: boolean;
  listsEnabled: boolean;
  featureExportCampaignExtract: boolean;
  dataUniverses: DataUniverse[];
  contextId: string;
  isProductTypeDcdp: boolean;
  dropdownListActions = [];
  filterDropdownListActionsArray = [];
  isContextExportDisabled: boolean;
  isProductTypeProspect: boolean;
  actionInProgress$ = new BehaviorSubject<{
    type: string;
    definitions: AudienceDefinition[];
  } | null>(null);
  showCreateList = false;
  successMessage: string;
  destroyErrorMessage: string;
  destroyErrorHeader: string;
  nestedDefs = '';
  filteredDefinitions$: Observable<AudienceDefinition[]>;
  absoluteUrl: string;
  hasAccess = false;
  initialLoad = true;
  dataUniverseId: string;
  isPaidchannelTypeFeatureEnabled = true;
  businessUnitId: string;
  isAudienceAI: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    public assetService: AssetService,
    protected tableFilterService: TableFilterService,
    private featureService: FeatureService,
    private builderService: AudienceBuilderService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    public featureAccessService: FeatureAccessService,
    private actions$: Actions,
    private audienceService: AudienceService,
    protected loadingService: LoadingService
  ) {
    this.formGroup = tableFilterService.formGroup;
    this.tableFilterService.searchSavedFilter$.subscribe(data => {
      this.onSearchSavedFilter(data);
    });
    
    this.businessUnitId = this.utilsService.getBusinessUnitId(this.route);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const businessUnitId = this.utilsService.getBusinessUnitId(this.route);
        if (businessUnitId !== this.businessUnitId) {
          this.businessUnitId = businessUnitId;
          this.resetPaginationPayloadToDefault();
          this.search();
        }
      }
    });
  }

  onSearchSavedFilter(selectedFilters) {
    this.initialLoad = true;
    if(selectedFilters === 'reset') {
      this.resetPaginationPayloadToDefault();
      this.tableFilterService.tags$.next([]);
      this.formGroup
      .get('search').setValue('');
    } else {
      this.paginationPayload.filter = selectedFilters?.filter;
      this.paginationPayload.sort = selectedFilters?.sort;
      this.paginationPayload.searchId = selectedFilters?.searchId;
      const searchText = this.paginationPayload.filter.filter(
        filter => filter.condition === AssetSearchFilterCondition.ILIKE
      )[0]?.values[0] || '';
      this.formGroup
      .get('search').setValue(searchText);
      const tags = this.paginationPayload.filter.filter(
        filter => filter.attribute === 'tags.tag'
      )[0]?.values || [];
      this.tableFilterService.tags$.next(tags);
    }
    this.filteredDefinitions = [];
    setTimeout(() => {
      this.search();
    }, 300);
  }

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.absoluteUrl =
      '/' +
      this.utilsService.getProductBaseUrl(this.router, this.route) +
      '/' +
      this.contextId +
      '/';

    this.store
      .select(selectTenantContext)
      .pipe(untilDestroyed(this))
      .subscribe((contextId) => {
        if (contextId && contextId !== this.contextId) {
          this.contextId = contextId;
        }
      });
    this.store.select(selectContext).pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.isAudienceAI =
          context?.metadata[CONTEXT_AUDIENCE_DEFINITION_AUDIENCE_AI];
      });
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
        if (context && !context.exportAudience) {
          this.isContextExportDisabled = true;
        }
        this.productType = context?.productType;
        if (context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.isProductTypeDcdp = true;
        }
        if (context?.productType === CabConstants.PROSPECT_PRODUCT_NAME) {
          this.isProductTypeProspect = true;
        }
        if (this.productType) {
          this.schedulingEnabled =
            this.featureService.isFeatureEnabled(FEATURE_SCHEDULING);
          this.listsEnabled =
            this.featureService.isFeatureEnabled(FEATURE_AUDIENCES);
          this.featureExportCampaignExtract =
            this.featureService.isFeatureEnabled(
              FEATURE_EXPORT_CAMPAIGN_EXTRACTS
            );
        }
        this.addActionsToTable();
        this.enabledDropdownListActions();
      });
    this.store
      .select(selectDataUniverses)
      .pipe(untilDestroyed(this))
      .subscribe((dataUniverses) => (this.dataUniverses = dataUniverses));

    this.store
      .select(selectActiveDataUniverse)
      .pipe(untilDestroyed(this), filter(isDefined))
      .subscribe((dataUniverse: DataUniverse) => {
        if (dataUniverse.id !== this.dataUniverseId) {
          this.dataUniverseId = dataUniverse.id;
          this.resetPaginationPayloadToDefault();
          if (!this.initialLoad) {
            this.search();
          }
        }
      });

    this.formGroup
      .get('search')
      .valueChanges.pipe(auditTime(250), startWith(''), pairwise(), untilDestroyed(this))
      .subscribe(([prevSearchText,searchText]) => {
        if(prevSearchText === searchText){
          return;
        }
        this.resetPaginationPayload();
        this.paginationPayload.filter = this.paginationPayload.filter?.filter(
          (val) => val?.attribute !== 'name'
        );
        this.paginationPayload.filter?.push(
          ...(searchText
            ? [
                {
                  attribute: 'name',
                  values: [searchText],
                  condition: AssetSearchFilterCondition.ILIKE,
                },
              ]
            : [])
        );

        if (!this.initialLoad) {
          this.search();
        }
      });

    this.tableFilterService.dateTypes$
      .pipe(untilDestroyed(this))
      .subscribe((dateType: any) => {
        this.resetPaginationPayload();
        this.paginationPayload.filter = this.paginationPayload.filter?.filter(
          (val) => val?.attribute !== 'data.lastModifiedDate'
        );
        const dateObj = {
          today: 0,
          yesterday: 1,
          recent3Days: 2,
          recent7Days: 6,
          recent30Days: 29,
        };
        let fromDateTime;
        let toDateTime;
        if (dateType?.length > 0 && dateObj.hasOwnProperty(dateType)) {
          const filterDate: number = dateObj[dateType];
          const format = 'YYYY-MM-DDTHH:mm:ss';
          fromDateTime = moment
            .utc(
              moment()
                .subtract(filterDate, 'days')
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
            )
            .format(format);
          if (dateType === 'yesterday') {
            toDateTime = moment
              .utc(
                moment()
                  .subtract(filterDate, 'days')
                  .hours(23)
                  .minutes(59)
                  .seconds(59)
                  .milliseconds(999)
              )
              .format(format);
          } else {
            toDateTime = moment
              .utc(moment().hours(23).minutes(59).seconds(59).milliseconds(999))
              .format(format);
          }
        }
        this.paginationPayload.filter?.push(
          ...(fromDateTime && toDateTime
            ? [
              {
                attribute: 'data.lastModifiedDate',
                values: [toDateTime],
                condition: AssetSearchFilterCondition.LTE,
              },
              {
                attribute: 'data.lastModifiedDate',
                values: [fromDateTime],
                condition: AssetSearchFilterCondition.GTE,
              },
            ]
            : [])
        );

        if (!this.initialLoad) {
          this.search();
        }
      });

    this.tableFilterService.statuses$
      .pipe(untilDestroyed(this))
      .subscribe((statuses: any[]) => {
        this.resetPaginationPayload();
        this.paginationPayload.filter = this.paginationPayload.filter?.filter(
          (val) => val?.attribute !== 'data.status'
        );
        this.paginationPayload?.filter?.push(
          ...(statuses?.length
            ? [
                {
                  attribute: 'data.status',
                  values: ['true'],
                  condition: AssetSearchFilterCondition.IN,
                },
              ]
            : [])
        );
        if (!this.initialLoad) {
          this.search();
        }
      });

    this.tagsFilterChange();
    this.getPaidChannelFlag();
    this.audienceDisplayNameTypesFilterChange();
  }

  public getPaidChannelFlag() {
    this.utilsService.isPaidChanelFeatureEnabled().pipe(untilDestroyed(this))
      .subscribe((isPaidChannelTypeEnabled) => {
        this.isPaidchannelTypeFeatureEnabled = isPaidChannelTypeEnabled;
      });
  }

  resetPaginationPayloadToDefault() {
    this.paginationPayload = {
      searchName: '',
      searchId: '',
      filter: [
        {
          attribute: 'type',
          values: ['AUDIENCE_DEFINITION'],
          condition: AssetSearchFilterCondition.IN,
        },
      ],
      limit: this.PAGE_LIMIT,
      offset: 0,
      sort: ['-data.lastModifiedDate'],
    };
    this.filteredDefinitions = [];
  }

  tagsFilterChange() {
    this.tableFilterService.tags$
      .pipe(untilDestroyed(this))
      .subscribe((tags: any[]) => {
        this.resetPaginationPayload();
        this.paginationPayload.filter = this.paginationPayload.filter?.filter(
          (val) => val?.attribute !== 'tags.tag'
        );
        this.paginationPayload?.filter?.push(
          ...(tags?.length > 0
            ? [
                {
                  attribute: 'tags.tag',
                  values: tags,
                  condition: AssetSearchFilterCondition.IN,
                },
              ]
            : [])
        );
        if (!this.initialLoad) {
          this.search();
        }
      });
  }

  audienceDisplayNameTypesFilterChange() {
    this.tableFilterService.audienceDisplayNameTypes$
      .pipe(untilDestroyed(this))
      .subscribe((audienceDisplayNameTypes: any[]) => {
        const currentFilter = this.paginationPayload?.filter || [];
        this.resetPaginationPayload();
        this.paginationPayload.filter = currentFilter;
        this.paginationPayload.filter = this.paginationPayload.filter?.filter(
          (val) =>
            val?.attribute !== 'data.audienceAttributes.channelType' &&
            val?.attribute !== 'data.dedupeIdentityType'
        );
        const hasCoreIdsFilter =
          audienceDisplayNameTypes.findIndex((d) => d.name === 'CORE IDs') >= 0;
        if (hasCoreIdsFilter) {
          this.paginationPayload.filter.push({
            attribute: 'data.audienceAttributes.channelType',
            values:
              audienceDisplayNameTypes.length > 1
                ? ['PAID', 'OWNED']
                : ['PAID'],
            condition: AssetSearchFilterCondition.IN,
          });
        } else if (audienceDisplayNameTypes.length) {
          this.paginationPayload.filter.push({
            attribute: 'data.audienceAttributes.channelType',
            values: ['OWNED'],
            condition: AssetSearchFilterCondition.IN,
          });
        }
        if (audienceDisplayNameTypes.length) {
          this.paginationPayload.filter.push({
            attribute: 'data.dedupeIdentityType',
            values: [].concat(...audienceDisplayNameTypes.map((d) => d.value)),
            condition: AssetSearchFilterCondition.IN,
          });
        }
        if (!this.initialLoad) {
          this.search();
        }
      });
  }

  search() {
    this.hasAccess = this.featureAccessService.hasAccess(
      Action.DEFINITION_VIEW
    );
    if (!this.hasAccess) {
      return;
    }
    this.loadingService.isHttpRequestLoading$.next(true);
    if (this.businessUnitId && this.businessUnitId !== 'DEFAULT') {
      if (!this.paginationPayload.filter) this.paginationPayload.filter = [];
      const buFilter = this.paginationPayload.filter.find(filter => filter.attribute === 'businessUnitId');
      if (buFilter) buFilter.values = [this.businessUnitId];
      else {
        this.paginationPayload.filter.push({
          attribute: "businessUnitId",
          condition: AssetSearchFilterCondition.IN,
          values: [this.businessUnitId]
        });
      }
    }
    this.assetService
      .search(this.paginationPayload)
      .subscribe((res: AssetSearchResponse) => {
        this.initialLoad = false;
        const getSearchResponse = res.results
          ? this.createFilteredDefinition(res.results, this.contextId)
          : [];
        const getFilteredDefinitionBasedOnSearchResponse = [
          ...this.filteredDefinitions,
          ...getSearchResponse,
        ];
        this.filteredDefinitions =
          getFilteredDefinitionBasedOnSearchResponse.map((definition) => ({
            ...definition,
            actions: this.getActionsForDefinition(definition),
          }));
        this.loadingService.isHttpRequestLoading$.next(false);
        this.hasMore = res.hasMore;
        this.paginationPayload.searchId = res.searchId ?? '';
        this.tableFilterService.searchId$.next(this.paginationPayload.searchId);
      });
  }

  public limitReached() {
    if (this.hasMore) {
      this.paginationPayload.offset += this.PAGE_LIMIT;
      this.search();
    }
  }

  scrollSort(sortOption: string) {
    if (this.scrollSortOption === sortOption) {
      this.scrollSortDirection =
        this.scrollSortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.scrollSortOption = sortOption;
      this.scrollSortDirection = 'ASC';
    }
    const sort = this.scrollSortDirection === 'DESC' ? `-${sortOption}` : sortOption;
    this.paginationPayload.sort = [sort];
    this.paginationPayload.searchId = '';
    this.filteredDefinitions = [];
    this.search();
  }

  public routeToEditHandler(definition) {
    const isPVEAudience = definition?.audienceAttributes?.isPVEAudience;
    const displayMode = isPVEAudience
      ? 'view'
      : this.featureAccessService.hasAccess(Action.AUDIENCE_DEFINITION_WRITE)
      ? 'edit'
      : 'view';
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      definition.dataUniverseId,
      'builder',
      displayMode,
      definition.id,
    ]);
  }

  public getAudienceDisplayName(
    dedupeType: string,
    channelType: string,
    alternateKeyType: string
  ): string {
    return this.builderService.getAudienceDisplayName(
      dedupeType,
      channelType,
      alternateKeyType
    );
  }

  public getDataUniverseDisplayName(dataUniverseId: string): string {
    const dataUniverse = this.dataUniverses.find(
      (dataUniverse) => dataUniverse.id === dataUniverseId
    );
    return dataUniverse?.displayName;
  }

  sortAscDesc(option: string | string[]): string | undefined {
    return this.scrollSortOption === option ||
      (Array.isArray(option) && option.includes(this.scrollSortOption))
      ? this.scrollSortDirection === 'ASC'
        ? 'ascending'
        : 'descending'
      : undefined;
  }

  createFilteredDefinition(searchResults: any, contextId:string): AudienceDefinition[] {
    return (searchResults || []).reduce((acc, item) => {
      acc.push(new Audience(this.getFlattedDefinitions({...item, contextId})));
      return acc;
    }, []);
  }

  getFlattedDefinitions(definition): AudienceDefinition {
    const { data, ...definitionWithoutData } = definition;
    return {
      ...data,
      ...definitionWithoutData,
      id: definition.assetId,
    };
  }

  public getActionsForDefinition(definition: AudienceDefinition) {
    if (this.isProductTypeDcdp) {
      this.addActionsToTable(definition);
      const getDataUniverseId = definition.dataUniverseId;
      const getDedupeTypeListSupported = getAudienceDedupeType(
        getDataUniverseId,
        definition.dedupeIdentityType,
        this.dataUniverses
      );
      if (getDedupeTypeListSupported?.listSupported) {
        this.dropdownListActions = this.dropdownListActions.filter(
          (item) =>
            item.display === 'Delete' ||
            item.display === 'Create Audience List' ||
            item.display === 'Deliver To...' ||
            item.display === 'Edit With Audience AI'
        );
      } else {
        this.dropdownListActions = this.dropdownListActions.filter(
          (item) =>
            item.display === 'Delete' || item.display === 'Deliver To...'
        );
      }
    }
    this.filterDropdownListActionsArray = reject(this.dropdownListActions, {
      disabled: true,
    });
    return this.filterDropdownListActionsArray;
  }

  public enabledDropdownListActions(): void {
    if (this.isProductTypeDcdp) {
      this.addActionsToTable();
      this.dropdownListActions = this.dropdownListActions.filter(
        (item) =>
          item.display === 'Delete' ||
          item.display === 'Create Audience List' ||
          item.display === 'Deliver To...' ||
          item.display === 'Edit With Audience AI'
      );
    }
    if (this.isProductTypeProspect) {
      this.addActionsToTable();
      this.dropdownListActions = this.dropdownListActions.filter(
        (item) => item.display === 'Delete'
      );
    }
    this.filterDropdownListActionsArray = reject(this.dropdownListActions, {
      disabled: true,
    });
  }

  getEditWithAIDropdowListAction(definition?: AudienceDefinition): object | null {
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_DEFINITION_WRITE) && this.isAudienceAI) {
      return {
        display: 'Edit With Audience AI',
        onClick: () => {
          this.router.navigate([
            this.utilsService.getProductBaseUrl(this.router, this.route),
            this.contextId,
            this.dataUniverseId,
            AUDIENCE_AI_DAILOG_TXT,
            definition?.audienceAttributes?.genAIConversationId
          ]);
        },
        disabled: !definition?.audienceAttributes?.genAIConversationId
      }
    }
    return null
  }

  private addActionsToTable(definition?: AudienceDefinition) {
    this.dropdownListActions = [
      {
        display: 'Edit',
        onClick: (definition) => {
          this.router.navigate([
            this.utilsService.getProductBaseUrl(this.router, this.route),
            this.contextId,
            definition.dataUniverseId,
            BUILDER_TXT,
            'edit',
            definition.id,
          ]);
        },
        disabled: false,
      },
      {
        display: 'Export',
        disabled:
          !this.featureExportCampaignExtract && this.isContextExportDisabled,
        onClick: (definition) => {
          this.actionInProgress$.next({
            type: 'export',
            definitions: [definition],
          });
        },
      },
    ];
    const editWithAiOption = this.getEditWithAIDropdowListAction(definition);
    if (editWithAiOption) {
      this.dropdownListActions.push(editWithAiOption);
    }
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_VIEW)) {
      this.dropdownListActions.push({
        display: 'View Details',
        onClick: (definition) => {
          this.router.navigate([
            this.utilsService.getProductBaseUrl(this.router, this.route),
            this.contextId,
            definition.dataUniverseId,
            DEFINITION_TXT,
            'view',
            definition.id,
          ]);
        },
        disabled: false,
      });
    }

    if (this.featureAccessService.hasAccess(Action.AUDIENCE_DEFINITION_WRITE)) {
      this.dropdownListActions.push({
        display: 'Create Audience List',
        onClick: (definition) => {
          this.showCreateList = true;
          this.store.dispatch(new LoadAudienceForCreateList({...definition, cabContextId:  this.contextId}));
        },
        disabled: !this.listsEnabled,
      });
    }

    if (
      this.featureAccessService.hasAccess(Action.DEFINITION_PAID_RUN) &&
      this.isProductTypeDcdp &&
      definition?.audienceAttributes?.channelType === 'PAID'
    ) {
      this.dropdownListActions.push({
        display: 'Deliver To...',
        onClick: (definition) => {
          this.sendToAMS(definition);
        },
        disabled: !this.isProductTypeDcdp,
      });
    }
    if (
      this.featureAccessService.hasAccess(Action.DEFINITION_OWNED_RUN) &&
      this.isProductTypeDcdp &&
      definition?.audienceAttributes?.channelType === 'OWNED'
    ) {
      this.dropdownListActions.push({
        display: 'Deliver To...',
        onClick: (definition) => {
          this.router.navigate(
            [
              this.utilsService.getProductBaseUrl(this.router, this.route),
              this.contextId,
              definition.dataUniverseId,
              definition.id,
              'sendTo',
            ],
            { queryParams: { cabId: definition.cabId, type: 'defintion' } }
          );
        },
        disabled: !this.isProductTypeDcdp,
      });
    }
    if (this.featureAccessService.hasAccess(Action.DEFINITION_DELETE)) {
      this.dropdownListActions.push({
        display: 'Delete',
        onClick: (definition) => {
          this.actionInProgress$.next({
            type: 'delete',
            definitions: [definition],
          });
          this.deleteActionOneModal.show();
        },
        disabled: false,
      });
    }
    return this.dropdownListActions;
  }

  sendToAMS(definition) {
    this.router.navigate(
      [
        this.utilsService.getProductBaseUrl(this.router, this.route),
        this.contextId,
        definition.dataUniverseId,
        definition.id,
        'ams',
      ],
      {
        queryParams: {
          audienceId: definition.audienceAttributes.amsAudienceId,
        },
      }
    );
  }

  public closeModal(modal: ModalComponent) {
    modal.hide();
    this.resetActionInProgress();
  }

  public resetActionInProgress() {
    this.actionInProgress$.next(null);
  }

  public deleteAction(modal: ModalComponent) {
    const actionDetails = this.actionInProgress$.getValue();
    if (actionDetails?.type === 'delete') {
      const definition = first(actionDetails.definitions);
      this.store.dispatch(new DestroyAudienceDefinition(definition));
      this.actions$
        .pipe(fetchOutcome(DestroyAudienceDefinition.type), take(1))
        .subscribe(
          () => {
            this.successMessage =
              'This definition has successfully been deleted.';
            this.destroySuccess.show();
            this.resetPaginationPayload();
            this.search();
          },
          ({ error }) => {
            const nestedDefs: Array<string> = JSON.parse(error).errorDetails
              ? JSON.parse(error)
                  .errorDetails[0].errorMessage.split('is referenced in ')[1]
                  .split(',')
              : [];
            this.destroyErrorHeader = 'Unable to delete this Object';
            if (nestedDefs.length > 1) {
              this.destroyErrorMessage =
                'This object can’t be deleted because they’re nested in the following ' +
                nestedDefs.length +
                '  definitions or lists.';
            } else {
              this.destroyErrorMessage =
                "This object can’t be deleted because it's nested in the following definition.";
            }
            this.nestedDefs =
              JSON.parse(error).errorDetails[0].errorMessage.split(
                'is referenced in '
              )[1];
            this.destroyError.show();
            this.resetPaginationPayload();
            this.search();
          }
        );
    } else if (actionDetails?.type === 'multiDelete') {
      this.audienceService.errorMessages = [];
      this.audienceService.successFullyDeletedIds = [];
      this.store.dispatch(
        new DestroyAudienceDefinitions(actionDetails.definitions)
      );
      this.actions$
        .pipe(fetchOutcome(DestroyAudienceDefinitions.type), take(1))
        .subscribe(
          () => {
            this.destroySuccess.show();
            this.resetPaginationPayload();
            this.search();
          },
          (error) => {
            const initDataLength = actionDetails.definitions.length;
            const errorMessages = error ? JSON.parse(error.message) : [];
            const successLength = initDataLength - errorMessages.length;
            if (successLength > 0) {
              this.successMessage =
                successLength + ' definitions are deleted successfully!';
              this.destroySuccess.show();
              this.audienceService.successFullyDeletedIds.forEach((value) => {
                const index = this.filteredDefinitions.findIndex(
                  (aud) => aud.id === value
                );
                this.filteredDefinitions.splice(index, 1);
                this.filteredDefinitions$ = of(this.filteredDefinitions);
                this.filteredDefinitions$
                  .pipe(
                    tap(this.tableFilterService.resetFormRows.bind(this)),
                    untilDestroyed(this)
                  )
                  .subscribe((definitions) => {
                    this.filteredDefinitions = definitions.map((definition) => ({
                      ...definition,
                      actions: this.getActionsForDefinition(definition),
                    }));
                    this.enabledDropdownListActions();
                  });
              });
            }
            if (successLength < initDataLength) {
              this.destroyErrorHeader =
                'Unable to delete the following objects';
              this.destroyErrorMessage =
                errorMessages.toString() +
                ' can’t be deleted because they’re nested in other definitions and lists.';
              this.nestedDefs = '';
              this.destroyError.show();
            }
            this.resetPaginationPayload();
            this.search();
          }
        );
    }
    this.closeModal(modal);
  }

  public closeCreateListModal(createListStatus?: 'success' | 'error') {
    if (createListStatus === 'success') {
      showToastNotification(this.successCreatingList);
    }
    this.showCreateList = false;
  }


  resetPaginationPayload() {
    this.paginationPayload.searchId = null;
    this.paginationPayload.limit = this.PAGE_LIMIT;
    this.paginationPayload.offset = 0;
    this.filteredDefinitions = [];
  }
}
