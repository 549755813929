import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeedDataResponse, FeedDataRequest } from './feed-data-list.model';
import { fetchResource } from '../utils/fetch-state';
import * as actions from './feed-data-list.actions';
import { CabConstants } from '../cab.constants';
import { UtilsService } from '../utils/utilservice';

@Injectable({
  providedIn: 'root',
})
export class FeedDataListService {
  feedDataList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.FeedDataList.type),
      fetchResource((action) =>
        this.feedDataList(action.contextId, action.searchRequest).pipe(
          map((feedData) => new actions.LoadFeedDataList(feedData))
        )
      )
    )
  );
  feedDataList(
    contextId: string,
    requestPayload: FeedDataRequest
  ): Observable<any> {
    const { assetSystem, assetType, limit,  businessUnitId } = requestPayload;
    const headers = {};
    headers[CabConstants.ADH_TENANT_HEADER] = contextId;
    let url = `${this.baseAssetsUrl()}/assets/search`;
    url += `?assetSystem=${assetSystem}&assetType=${assetType}&limit=${limit}&query=tags.Feed-Function==CDP-Audience-Delivery and tags.CAB-Audience-Id=ex=true`;
    if (businessUnitId !== null && businessUnitId !== undefined) {
      url += ` and tags.businessUnitId==${businessUnitId}`;
    }
    return this.http.get(url, { headers }).pipe(
      map((res: FeedDataResponse) => {
        return {
          feedData: res.assets.map((asset) => ({
            ...asset,
            id: asset.assetId,
          })),
          hasMore: res.hasMore,
        };
      }),
      catchError((err) => {
        return err;
      })
    );
  }

  constructor(private http: HttpClient, private actions$: Actions, private utilsService: UtilsService) {}

  private baseAssetsUrl(): string {
    return this.utilsService.getDataHubAPIUrl() + '/assets-metadata-api/v1';
  }
}
