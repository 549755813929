<div [formGroup]="formGroup" class="page-scaffolding">
  <coreui-table class="scroll-table"  [hasVerticalLines]="false" #scrollTable coreUIInfiniteScroll [scrollTarget]="scrollTable.scrollContainer"
  (scrollLimitReached)="limitReached()" [fixedHeader]="true" *ngIf="tableType === 'paid'">
      <colgroup>
        <col width="5%"/><!-- //NOSONAR -->
        <col width="22%" /><!-- //NOSONAR -->
        <col width="22%" /><!-- //NOSONAR -->
        <col width="22%" /><!-- //NOSONAR -->
        <col width="22%" /><!-- //NOSONAR -->
        <col />
      </colgroup>
      <thead coreUITableSort>
          <tr coreUITableHeaderRow>
              <th scope="col" class="table-header"></th>
              <th scope="col" class="table-header">
                  <coreui-table-multiple-header-column label="Sent Date">
                      <coreui-table-header-column (sort)="sort($event, 'createdDate')"
                          [active]="scrollSortOption === 'createdDate'" [sortDirection]="scrollSortDirection">
                          Date
                      </coreui-table-header-column>
                      <coreui-table-header-column (sort)="sort($event, 'createdBy')"
                          [active]="scrollSortOption === 'createdBy'" [sortDirection]="scrollSortDirection">
                          Started By
                      </coreui-table-header-column>
                  </coreui-table-multiple-header-column>
              </th>
              <th scope="col" class="table-header">
                  <coreui-table-header-column (sort)="sort($event, 'displayName')"
                      [active]="scrollSortOption === 'displayName'" [sortDirection]="scrollSortDirection">
                      Audience Sent
                  </coreui-table-header-column>
              </th>
              <th scope="col" class="table-header not-sortable-header">
                <coreui-table-header-column>
                    Delivery ID
                </coreui-table-header-column>  
              </th>
              <th scope="col" class="table-header">
                  <coreui-table-multiple-header-column label="Delivery Status">
                    <coreui-table-header-column (sort)="sort($event, 'status')"
                          [active]="scrollSortOption === 'status'" [sortDirection]="scrollSortDirection">
                          Status
                      </coreui-table-header-column>
                      <coreui-table-header-column (sort)="sort($event, 'lastModifiedDate')"
                          [active]="scrollSortOption === 'lastModifiedDate'" [sortDirection]="scrollSortDirection">
                          Date
                      </coreui-table-header-column>  
                  </coreui-table-multiple-header-column>
              </th>
              <th scope="col" class="table-header"></th>
          </tr>
      </thead>
      <tbody>
          <ng-container *ngIf="!isLoader">
              <ng-template #recursiveChildTable let-items>
                  <ng-container *ngFor="let item of filteredCampaignExtracts; let i = index" coreUITableRow>
                      <ng-container>
                          <tr coreUITableRow>
                              <th scope="colgroup" [attr.colspan]="7">
                                  <coreui-table-children [header]="headerTemplate" collapseIcon="collapse"
                                      expandIcon="add" (activeChange)="onRowPlusClick($event._active, item)">
                                      <div class="inner-wrapper" *ngIf="connections[item?.sendToTargetInfo?.amsActivationId]">
                                          <coreui-load-mask [active]="connections[item?.sendToTargetInfo?.amsActivationId]?.isLoading">
                                              <div *ngIf="connections[item?.sendToTargetInfo?.amsActivationId]?.data">
                                                  <div class="inner-container">
                                                      <div class="header-container">
                                                          <div class="destination text-style-muted-1 semibold">Destination</div>
                                                          <div class="destination-status text-style-muted-1 semibold">Destination Status</div>
                                                      </div>
                                                      <div class="data-container" *ngFor="let connection of connections[item?.sendToTargetInfo?.amsActivationId]?.data">
                                                          <div class="destination text-style-2">
                                                              <img alt="connector logo" class="connector-logo" [src]="fetchConnectorLogos(connection?.destination?.platform?.name)">
                                                              {{ connection?.destination?.platform?.name ? connection?.destination?.platform?.name : '—' }}
                                                          </div>
                                                          <div class="destination-status text-style-2">
                                                              <coreui-badge color="Pineapple">
                                                                  {{ connection?.displayState ? connection?.displayState : '—' }}
                                                              </coreui-badge>
                                                              <div>
                                                                  on {{ connection?.updatedAt | date: 'MM/dd/yyyy' }}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </coreui-load-mask>
                                      </div>
                                  </coreui-table-children>
                              </th>
                          </tr>
                      </ng-container>
                      <ng-template #headerTemplate>
                          <coreui-table [hasVerticalLines]="false" >
                              <colgroup>
                                <col width="22%"/><!-- //NOSONAR -->
                                <col width="22%"/><!-- //NOSONAR -->
                                <col width="22%"/><!-- //NOSONAR -->
                                <col width="22%"/><!-- //NOSONAR -->
                                <col width="7%"/><!-- //NOSONAR -->
                              </colgroup>
                              <tbody>
                                  <tr coreUITableRow>
                                      <td>
                                          <div class="has-bottom-space">{{ item.createdDate }}</div>
                                          <div class="text-style-muted-3">By {{ item.createdBy }}</div>
                                      </td>
                                      <td class="nameCol"><div>
                                        <i
                                        [coreUIIcon]="item?.audienceDefinitionId ? 'audiences' : 'list'"></i><span>{{ item.displayName }}</span>
                                      </div></td>
                                      <td>{{item?.sendToTargetInfo.amsActivationId }}</td>
                                      <td>
                                          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Completed'" color="Lime"
                                              [hasIcon]="true" [subMessage]="lastModifiedDate"><i
                                                  coreUIIcon="successShield"></i>Completed</coreui-badge>
                                          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Failed'" color="Apple" [hasIcon]="true"
                                              [subMessage]="lastModifiedDate"><i coreUIIcon="errorShield"></i>Failed</coreui-badge>
                                          <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Processing'" color="Slate"
                                              [hasIcon]="true" [subMessage]="lastModifiedDate"><i
                                                  coreUIIcon="spinner"></i>Processing</coreui-badge>
                                          <ng-template #lastModifiedDate>on {{ item.lastModifiedDate }}</ng-template>
                                      </td>
                                      <td coreUITableActionColumn>
                                          <a [routerLink]="absoluteUrl + item.dataUniverseId + '/' + 'monitor' + '/' + 'view' + '/' + item.id">
                                              <button type="button" coreUIButton="icon" tabindex="0" withBorder><i scale="1.5" coreUIIcon="chevronRight" ></i></button>
                                          </a>
                                      </td>
                                  </tr>
                              </tbody>
                          </coreui-table>
                      </ng-template>
                  </ng-container>
                <tr *ngIf="!filteredCampaignExtracts.length && hasAccess" coreUITableRow [disableHover]="true">
                    <td colspan="8" class="empty-list">
                        <coreui-graphic-message label="No Results Found" graphic="noResults">
                            Sorry, we did not find any results for your search.
                        </coreui-graphic-message>
                    </td>
                </tr>
              </ng-template>
              <ng-container
                  *ngTemplateOutlet="recursiveChildTable; context:{ $implicit: deepDataSource1 | async }"></ng-container>
          </ng-container>
          <ng-container *ngIf="isLoader">
              <tr coreUITableRow>
                  <td colspan="2"><coreui-loading-table-column></coreui-loading-table-column></td>
                  <td><coreui-loading-table-column></coreui-loading-table-column></td>
                  <td><coreui-loading-table-column></coreui-loading-table-column></td>
                  <td><coreui-loading-table-column></coreui-loading-table-column></td>
                  <td><coreui-loading-table-column></coreui-loading-table-column></td>
              </tr>
          </ng-container>
      </tbody>
  </coreui-table>
  <coreui-table class="scroll-table" #scrollTable coreUIInfiniteScroll [scrollTarget]="scrollTable.scrollContainer"
      (scrollLimitReached)="limitReached()" [fixedHeader]="true" *ngIf="tableType !== 'paid' && !enableUIADHActivityListing">
      <thead coreUITableSort>
          <tr coreUITableHeaderRow>
              <th scope="col" class="table-header" [attr.aria-sort]="sortAscDesc(['createdDate', 'createdBy'])"
        [attr.aria-describedby]="scrollSortOption === 'createdDate' ? 'Date-Sort' : scrollSortOption === 'createdBy' ? 'StartedBy-Sort' :undefined">
                  <coreui-table-multiple-header-column label="Sent Date">
                      <coreui-table-header-column coreUIMenuItem (sort)="sort($event, 'createdDate')"
                          [active]="scrollSortOption === 'createdDate'" [sortDirection]="scrollSortDirection">
                          Date
                      </coreui-table-header-column>
                      <coreui-table-header-column coreUIMenuItem (sort)="sort($event, 'createdBy')"
                          [active]="scrollSortOption === 'createdBy'" [sortDirection]="scrollSortDirection">
                          Started By
                      </coreui-table-header-column>
                  </coreui-table-multiple-header-column>
              </th>
              <th scope="col" class="table-header" [attr.aria-sort]="sortAscDesc('displayName')">
                  <coreui-table-header-column (sort)="sort($event, 'displayName')"
                      [active]="scrollSortOption === 'displayName'" [sortDirection]="scrollSortDirection">
                      Audience Sent
                  </coreui-table-header-column>
              </th>
              <th scope="col" class="table-header not-sortable-header">
                  Sent To
              </th>
              <th scope="col" class="table-header">
                  <coreui-table-header-column [active]="scrollSortOption === 'count'" [disableSort]="true">
                      Count
                  </coreui-table-header-column>
              </th>
              <th scope="col" class="table-header" [attr.aria-sort]="sortAscDesc('lastModifiedDate')"
        [attr.aria-describedby]="scrollSortOption === 'lastModifiedDate' ? 'Date-Sort' :undefined">
                  <coreui-table-multiple-header-column label="Delivery Status">
                      <coreui-table-header-column (sort)="sort($event, 'status')"
                          [active]="scrollSortOption === 'status'" [sortDirection]="scrollSortDirection">
                          Status
                      </coreui-table-header-column>
                      <coreui-table-header-column coreUIMenuItem (sort)="sort($event, 'lastModifiedDate')"
                          [active]="scrollSortOption === 'lastModifiedDate'" [sortDirection]="scrollSortDirection">
                          Date
                      </coreui-table-header-column>
                  </coreui-table-multiple-header-column>
              </th>
              <th scope="col" class="table-header"></th>
          </tr>
      </thead>
      <tbody formArrayName="rows">
          <tr *ngFor="let item of filteredCampaignExtracts; let i = index" coreUITableRow>
              <td>
                  <div class="has-bottom-space">{{ item.createdDate }}</div>
                  <div class="text-style-muted-3">By {{ item.createdBy }}</div>
              </td>
              <td class="nameCol"><div>
                <i scale ="1.25"
                [coreUIIcon]="item?.audienceDefinitionId ? 'audiences' : 'list'"></i><span>{{ item.displayName }}</span>
              </div></td>
              <td>
                  <div [class.has-bottom-space]="item.sendToTargetInfo?.connectionDisplayName">
                      {{checkForSendToTargetType(item)}}</div>
                  <span class="text-style-muted-1">{{ item.sendToTargetInfo?.connectionDisplayName ?? '' }}</span>
              </td>
              <td>
                  <span *ngIf="item?.count !== null && item?.status !== 'Error'">{{item?.count | number}}</span>
                  <span *ngIf="item?.count === null || item?.status === 'Error'">—</span>
              </td>
              <td>
                  <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Completed'" color="Lime"
                      [hasIcon]="true" [subMessage]="lastModifiedDate"><i
                          coreUIIcon="successShield"></i>Completed</coreui-badge>
                  <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Failed'" color="Apple" [hasIcon]="true"
                      [subMessage]="lastModifiedDate"><i coreUIIcon="errorShield"></i>Failed</coreui-badge>
                  <coreui-badge class="badgeWithTime" *ngIf="item.status === 'Processing'" color="Slate"
                      [hasIcon]="true" [subMessage]="lastModifiedDate"><i
                          coreUIIcon="spinner"></i>Processing</coreui-badge>
                  <ng-template #lastModifiedDate>on {{ item.lastModifiedDate }}</ng-template>
              </td>
              <td>
                  <a [href]="absoluteUrl + item.dataUniverseId + '/' + 'monitor' + '/' + 'view' + '/' + item.id">
                      <button coreUIButton="icon" tabindex="0"><i scale="2.8" coreUIIcon="chevronRight"></i></button>
                  </a>
              </td>
          </tr>
          <tr coreUITableRow *ngIf="isLoader">
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
              <td><coreui-loading-table-column></coreui-loading-table-column></td>
          </tr>
      </tbody>

      <tr *ngIf="((loadingService.isLoading$ | async) === null || (loadingService.isLoading$ | async) === undefined || (loadingService.isLoading$ | async) === false) && !isLoader && !filteredCampaignExtracts.length && hasAccess"
          coreUITableRow [disableHover]="true">
          <td colspan="8" class="empty-list">
              <coreui-graphic-message label="No Results Found" graphic="noResults">
                  Sorry, we did not find any results for your search.
              </coreui-graphic-message>
          </td>
      </tr>

      <tr *ngIf="!searchPayload?.displayName && !filteredCampaignExtracts.length && !loadingService.isLoading$"
          coreUITableRow [disableHover]="true">
          <td colspan="8" class="empty-list">
              <coreui-graphic-message label="Nothing Here" graphic="empty">
                  There are no saved definitions.
              </coreui-graphic-message>
          </td>
      </tr>
  </coreui-table>
</div>
