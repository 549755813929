import {Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HourFormat} from '@epsilon/core-ui/time-picker/hour-format';
import {FormTabChangeEvent, FormTabGroupComponent} from '@epsilon/core-ui';
import {Subscription} from 'rxjs';
import {ScheduleDetail} from '../../../audience-overview/details/schedules/schedules.models';

@Component({
  selector: 'lib-schedule-audience-modal',
  templateUrl: './schedule-audience-modal.component.html',
  styleUrls: ['./schedule-audience-modal.component.sass']
})
export class ScheduleAudienceModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isEditScheduler = false;
  @Input() dataType = '';
  @Input() scheduleDetails: ScheduleDetail;
  minDate;
  schedulerFrequencyList = [{name: 'Hourly', value: 'Hour'}, {name: 'Daily', value: 'Day'}, {name: 'Weekly', value: 'Week'}, {name: 'Monthly', value: 'Month'}];
  schedulerDaysList = [{name: 'Monday', value: 'Monday'}, {name: 'Tuesday', value: 'Tuesday'}, {name: 'Wednesday', value: 'Wednesday'},
    {name: 'Thursday', value: 'Thursday'}, {name: 'Friday', value: 'Friday'}, {name: 'Saturday', value: 'Saturday'}, {name: 'Sunday', value: 'Sunday'}];
  schedulerRanksMonthList = [{name: 'First', value: 'First'}, {name: 'Second', value: 'Second'}, {name: 'Third', value: 'Third'}, {name: 'Fourth', value: 'Fourth'}
    , {name: 'Last', value: 'Last'}];
  hourFormat: HourFormat = '12';

  schedulerFormGroup: UntypedFormGroup = new UntypedFormGroup({
    scheduleName: new UntypedFormControl('', Validators.required),
    schedulerFrequency: new UntypedFormControl(),
    run: new UntypedFormControl('', Validators.required),

    //Daily Frequency Fields
    timePickerForDaysFrequency: new UntypedFormControl('', Validators.required),

    //Weekly Frequency Fields
    schedulerWeeks: new UntypedFormControl(),
    scheduleSectionForWeek: new UntypedFormControl('everyweek'),
    recurrenceStartDateTime: new UntypedFormControl('', Validators.required),
    recurrenceEndDateTime: new UntypedFormControl('', Validators.required),
    recurrenceTimeEndingRadio: new UntypedFormControl(),
    recurrenceAfterOccurrences: new UntypedFormControl(),
    atWeeklyTimePicker: new UntypedFormControl(),
    selectEveryWeekFrequency: new UntypedFormControl(),
    scheduleRunEveryWeekList: new UntypedFormControl(),

    //Monthly Frequency Fields
    dayOrRankForMonth: new UntypedFormControl('DAY'),
    dayOfMonth: new UntypedFormControl(),
    timePickerForDayOfMonth: new UntypedFormControl(),
    rankOfDayInMonth: new UntypedFormControl(),
    dayInWeekForMonth: new UntypedFormControl(),
    timePickerForWeekForMonth: new UntypedFormControl()
  });
  @ViewChild(FormTabGroupComponent, {static: true}) public formTab: FormTabGroupComponent;
  isFutureSchedule = false;
  private formTabChangeSubscription: Subscription;
  everySelection = 'everyweek';

  constructor() {
    // ScheduleAudienceModalComponent constructor
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['scheduleDetails']?.currentValue) {
      this.populateScheduleInfo();
    }
  }

  ngOnInit(): void {
    if (!this.isEditScheduler) {
      this.schedulerFormGroup.get('schedulerFrequency').setValue('Hour');
      this.schedulerFormGroup.get('dayInWeekForMonth').setValue('Monday');
    }
    this.formTabChangeSubscription = this.formTab.tabValue.subscribe(
      (event: FormTabChangeEvent) => {
        if (event.tabLabel === 'Run later on a schedule') {
          this.isFutureSchedule = true;
        } else {
          this.isFutureSchedule = false;
        }
      }
    );
    this.minDate = new Date();
    this.schedulerDaysList.forEach((daylist, index) => {
      this.schedulerFormGroup.addControl(daylist.value, new UntypedFormControl(index === 0));
    });
    this.schedulerFormGroup.get('scheduleSectionForWeek').valueChanges.subscribe(value => {
      if (value === 'every') {
        this.schedulerDaysList.forEach((daylist) => {
          this.schedulerFormGroup.get(daylist.value).setValue(false);
        });
      } else {
        this.schedulerFormGroup.get('selectEveryWeekFrequency').setValue(1);
      }

      this.everySelection = value;
    });
  }

  sequence(sequenceOfNumber: number) {
    return Array(sequenceOfNumber).fill(0, 0, sequenceOfNumber).map((element, index) => index + 1);
  }

  checkSchedulerFormValidation(){
    this.schedulerFormGroup.markAllAsTouched();
  }

  getScheduleInfo() {
    const scheduleFrequencyValue = this.schedulerFormGroup.get('schedulerFrequency')?.value;
    const scheduleInfo: any = {};
    scheduleInfo.scheduleRecurrence = {};
    switch (scheduleFrequencyValue) {
      case 'Hour':
        scheduleInfo.scheduleRecurrenceType = 'HOURLY';
        scheduleInfo.scheduleRecurrence.interval = this.schedulerFormGroup.get('run')?.value;
        break;
      case 'Day':
        scheduleInfo.scheduleRecurrenceType = 'DAILY';
        scheduleInfo.scheduleRecurrence.dayInterval = this.schedulerFormGroup.get('run')?.value;
        scheduleInfo.scheduleRecurrence.timeOfTheDay = this.convertTimeString(this.schedulerFormGroup.get('timePickerForDaysFrequency')?.value);
        break;
      case 'Week':
        scheduleInfo.scheduleRecurrenceType = 'WEEKLY';
        scheduleInfo.scheduleRecurrence.weeklyInterval = this.schedulerFormGroup.get('selectEveryWeekFrequency')?.value;
        scheduleInfo.scheduleRecurrence.timeOfTheDay = this.convertTimeString(this.schedulerFormGroup.get('atWeeklyTimePicker')?.value);
        this.schedulerDaysList.forEach(week => scheduleInfo.scheduleRecurrence[week.value.toLowerCase()] = this.schedulerFormGroup.get(week.value).value);
        break;
      case 'Month':
        scheduleInfo.scheduleRecurrenceType = 'MONTHLY';
        scheduleInfo.scheduleRecurrence.monthInterval = this.schedulerFormGroup.get('run')?.value;
        scheduleInfo.scheduleRecurrence.scheduleType = this.schedulerFormGroup.get('dayOrRankForMonth')?.value;

        if (scheduleInfo.scheduleRecurrence.scheduleType === 'DAY') {
          scheduleInfo.scheduleRecurrence.day = this.schedulerFormGroup.get('dayOfMonth')?.value;
          scheduleInfo.scheduleRecurrence.timeOfTheDay = this.convertTimeString(this.schedulerFormGroup.get('timePickerForDayOfMonth')?.value);
        } else {
          scheduleInfo.scheduleRecurrence.rank = this.schedulerFormGroup.get('rankOfDayInMonth')?.value;
          scheduleInfo.scheduleRecurrence.weekDay = this.schedulerFormGroup.get('dayInWeekForMonth')?.value;
          scheduleInfo.scheduleRecurrence.timeOfTheDay = this.convertTimeString(this.schedulerFormGroup.get('timePickerForWeekForMonth')?.value);
        }
        break;
    }
    scheduleInfo.startDate = this.schedulerFormGroup.get('recurrenceStartDateTime')?.value;
    scheduleInfo.endType = this.schedulerFormGroup.get('recurrenceTimeEndingRadio')?.value;
    if (this.schedulerFormGroup.get('recurrenceTimeEndingRadio')?.value === 'END_DATE') {
      scheduleInfo.endDate = this.schedulerFormGroup.get('recurrenceEndDateTime')?.value;
    } else if (this.schedulerFormGroup.get('recurrenceTimeEndingRadio')?.value === 'RECURRENCE') {
      scheduleInfo.noOfOccurrences = this.schedulerFormGroup.get('recurrenceAfterOccurrences')?.value;
    }
    return scheduleInfo;
  }

  convertTimeString(time: any) {
    let timeString = time.hour < 10 ? '0' + time.hour : time.hour;
    if (time.period.toUpperCase() === 'PM') {
      timeString = Number(timeString) + 12;
    }
    timeString = timeString + ':' + (time.minute < 10 ? '0' + time.minute : time.minute) + ':' + '00';
    return timeString;
  }

  getName() {
    return this.schedulerFormGroup.get('scheduleName')?.value;
  }

  timeConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])/) || [time];
    time.splice(3, 1);
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[2] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    const timeJson = {
      hour: time[0],
      minute: time[1],
      period: time[2]
    };
    return timeJson; // return adjusted time or original string
  }


  populateScheduleInfo() {
    this.isFutureSchedule = true;
    this.schedulerFormGroup.get('scheduleName').setValue(this.scheduleDetails.name);
    this.schedulerFormGroup.get('recurrenceTimeEndingRadio').setValue(this.scheduleDetails.scheduleIntervalInfo.endType);
    this.schedulerFormGroup.get('recurrenceStartDateTime').setValue(new Date(this.scheduleDetails.scheduleIntervalInfo.startDate));
    if (this.scheduleDetails.scheduleIntervalInfo.endType === 'END_DATE') {
      this.schedulerFormGroup.get('recurrenceEndDateTime').setValue(new Date(this.scheduleDetails.scheduleIntervalInfo.endDate));
     }
    else if (this.scheduleDetails.scheduleIntervalInfo.endType === 'RECURRENCE') {
      this.schedulerFormGroup.get('recurrenceAfterOccurrences').setValue(this.scheduleDetails.scheduleIntervalInfo.noOfOccurrences);
    }
    const scheduleFrequencyValue = this.scheduleDetails?.scheduleIntervalInfo?.scheduleRecurrenceType;
    switch (scheduleFrequencyValue) {
      case 'HOURLY':
        this.schedulerFormGroup.get('schedulerFrequency').setValue('Hour');
        this.schedulerFormGroup.get('run').setValue(this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.interval);
        break;
      case 'DAILY':
        this.schedulerFormGroup.get('schedulerFrequency').setValue('Day');
        this.schedulerFormGroup.get('run').setValue(this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.dayInterval);
        this.schedulerFormGroup.get('timePickerForDaysFrequency').setValue(this.timeConvert(this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.timeOfTheDay));
        break;
      case 'WEEKLY':
        this.schedulerFormGroup.get('schedulerFrequency').setValue('Week');
        break;
      case 'MONTHLY':
        this.schedulerFormGroup.get('schedulerFrequency').setValue('Month');
        this.schedulerFormGroup.get('run').setValue(this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.monthInterval);
        if (this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.scheduleType === 'RANK_OF_THE_WEEK') {
          this.schedulerFormGroup.get('dayOrRankForMonth').setValue('RANK_OF_THE_WEEK');
          this.schedulerFormGroup.get('rankOfDayInMonth').setValue((this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.rank));
          this.schedulerFormGroup.get('dayInWeekForMonth').setValue((this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.weekDay));
          this.schedulerFormGroup.get('timePickerForWeekForMonth').setValue(this.timeConvert(this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.timeOfTheDay));
        } else {
          this.schedulerFormGroup.get('scheduleSectionForMonth').setValue('scheduleOptionForMonth');
          this.schedulerFormGroup.get('dayOfMonth').setValue((this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.day));
          this.schedulerFormGroup.get('timePickerForDayOfMonth').setValue(this.timeConvert(this.scheduleDetails.scheduleIntervalInfo.scheduleRecurrence.timeOfTheDay));
        }
        break;
    }
  }

  public ngOnDestroy() {
    this.formTabChangeSubscription.unsubscribe();
  }
}

