import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG_MAP } from '@epsilon-cdp/cab-lib';

@Injectable({
  providedIn: 'root'
})
export class OverrideConfigService {
  constructor(@Inject('ENVIRONMENT') private environment: string) {}

  getEnvironmentConfig() {
    return {
      baseKernelApiUrl: ENV_CONFIG_MAP.get(this.environment).foundationApiUrl
    };
  }
}
