import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DedupeType } from '../models/dedupe-type';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { CountsService } from '../counts/counts.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { selectContext } from '../context/context.reducer';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CabConstants } from '../cab.constants';
import { NotificationComponent } from '@epsilon/core-ui';
import { DataUniverse } from '../data-universe/data-universe.models';
import { Audience, AudienceDefinition } from '../audience/audience.models';
import { CampaignExtract } from '../campaign-extract/campaign-extract.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'lib-profile-section',
  templateUrl: './profile-section.component.html',
  styleUrls: ['./profile-section.component.sass']
})
export class ProfileSectionComponent implements OnInit {
  @ViewChild('updateCancleCountModel')
  public updateCancleCountModel;
  @Input() audienceForm: UntypedFormGroup;
  @Input() isEdit: boolean;
  @Input() dataUniverse: DataUniverse;
  @Input() audience: Audience | AudienceDefinition | CampaignExtract;
  @Input() dedupeDisplayName: string;
  @Input() activeDedupeType: DedupeType;
  @Output() submitUpdateCount: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() handleInvalidDefinitionError: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitCurrentAudience = new EventEmitter<any>();
  errorOnLoadState$ = new BehaviorSubject<boolean>(false);
  isProductTypeDcdp: boolean;

  constructor(
    public builderService: AudienceBuilderService,
    public countsService: CountsService,
    public store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.isProductTypeDcdp = true;
        }
      });
  }

  getDedupType() {
    if (this.isProductTypeDcdp) {
      return this.builderService.getAudienceDisplayName(this.audienceForm?.get('dedupeType')?.value || this.builderService.audience?.dedupeIdentityType,
      this.audienceForm?.get('channelType')?.value || this.builderService.audience?.audienceAttributes?.channelType);
    }
    return (this.dedupeDisplayName || this.activeDedupeType);
  }

  updateCount(countUpdated: NotificationComponent) {
    if (!this.canSaveDefinition()) {
      // TODO: show error notification
      this.audienceForm.get('displayName').markAsTouched();
      this.audienceForm.get('includeConditions').markAllAsTouched();
      this.audienceForm.get('dedupeType').markAsTouched();
      this.handleInvalidDefinitionError.emit();
      return;
    }
    countUpdated.show();
    this.submitUpdateCount.emit();
  }

  canSaveDefinition() {
    this.builderService.checkForAttributeValues();
    return this.isAudienceValid();
  }

  isAudienceValid() {
    const attributeFromFilterValueErrors = Object.keys(this.builderService.attributeFromFilterValueErrorMap);
    if (attributeFromFilterValueErrors.length) {
      if (attributeFromFilterValueErrors.filter(key => this.builderService.attributeFromFilterValueErrorMap[key]).length) {
        return false;
      }
    }
    return (
      this.builderService.definitionHasAttribute$.value &&
      this.builderService.allExpressionsHaveValues$.value &&
      this.audienceForm.valid
    );
  }

  isSaveButtonDisabled() {
    return !this.builderService.definitionHasAttribute$.value;
  }

  cancelCount(){
    this.updateCancleCountModel.show();
  }

  toggleCancel(type: string){
    if(type === 'continue'){
      this.countsService.stopCron();
    }
    this.updateCancleCountModel.hide();
  }

  currentAudienceDefinition(){
    this.emitCurrentAudience.emit();
  }

}
