<coreui-notification #generalErrorNotification notificationStyle="error" [isPersistent]="false">
  There was an error while attempting your action. Please try again later.
</coreui-notification>
<section class="tree-picker-container" [ngClass]="{'disableNodeClick' : loadingNodes}">
  <coreui-card class="custom-width-card">
    <h2>Attribute Selection</h2>
    <form class="Core-Form" *ngIf="attributeSearchEnabled" [style.display]="(breadcrumbs.length > 0) ? 'block' : 'none' " [formGroup]="attributeSearchFormGroup">
      <div class="search-container" [ngClass]="{'new-search-group': (breadcrumbs.length > 0)}"
        *ngIf="breadcrumbs.length > 0">
        <input name="search" [(ngModel)]="searchText"
          placeholder="Search"
          formControlName="displayName" type="text" coreUIInput class="attribute-search" />
        <button (click)="attributeSearch()" coreUIButton>
          <i coreUIIcon="search" scale="1.6"></i>
        </button>
        <!-- <button coreUIButton="icon-large"><i coreUIIcon="trash"></i></button> -->
      </div>
      <span *ngIf="showSearchLimit && (breadcrumbs.length > 1)"
        class="text-style-error error CharSearch">Enter a minimum of 3 characters to begin search</span>
      <div class="searchResultInfoBar">
        <span *ngIf="(searchNodes && searchNodes.length !== 0 && showSearchTree)"
          class="text-style-5">{{searchNodes.length}} result<span
            *ngIf="(searchNodes.length !== 1)">s</span></span>
        <span *ngIf="(searchNodes && searchNodes.length !== 0) && showSearchTree"
          class="text-style-muted-5 dot">|</span>
        <a href="javascript:void(0)" *ngIf="showSearchTree" (click)="clearAttributeSearch()" class="text-style-5">Clear search</a>
      </div>
      <!-- <div *ngIf="(searchNodes && searchNodes.length !== 0 && showSearchTree)" coreUIDivider></div> -->

      <coreui-graphic-message graphic="noResults" label="No Results Found"
        *ngIf="!isLoading && (searchNodes && searchNodes.length === 0) && (showSearchTree && !showSearchLimit)">
        Sorry, we did not find any results for your search.
      </coreui-graphic-message>
    </form>

    <coreui-breadcrumbs *ngIf="!showSearchTree" [style.display]="(breadcrumbs.length > 0)? 'block' : 'none' " [includeBottomMargin]="false">
      <a href="javascript:void(0)" *ngIf="breadcrumbs?.length > 0" coreUIBreadcrumbLink
        (click)="navigateToLink(null)"><span> All Criteria</span>
      </a>
      <span coreUIBreadcrumbSlash *ngIf="breadcrumbs?.length === 1"></span>
      <ng-container *ngIf="breadcrumbs?.length > 1">
        <span coreUIBreadcrumbSlash></span>
        <coreui-menu coreUIBreadcrumbMenu menuButtonStyle="link" icon="ellipsis" menuAlignment="left"
          showMenuAction="hover">
          <coreui-menu-section>
            <ng-container *ngFor="let breadcrumb of breadcrumbs; let breadcrumbIndex = index; let last = last">
              <a href="javascript:void(0)" coreUIMenuItem *ngIf="!last"
                (click)="navigateToLink(breadcrumbIndex)"><span>{{breadcrumb.displayName}}</span>
              </a>
            </ng-container>
          </coreui-menu-section>
        </coreui-menu>
        <span coreUIBreadcrumbSlash></span>
      </ng-container>
      <a href="javascript:void(0)" *ngIf="breadcrumbs?.length > 0" coreUIBreadcrumbEnd>{{breadcrumbs[breadcrumbs.length
        - 1]?.displayName }}</a>
    </coreui-breadcrumbs>

    <ng-template #treeNode let-nodes="nodes" let-parent="parent">
      <div *ngFor="let node of nodes">
        <coreui-tree-progressive-pane [disabled]="loadingNodes" id="node-tree-cab-{{node['cabId']}}" *ngIf="!node.leaf"
          [header]="node.displayName" (activeChange)="fetchChildrenNew(node)">
          <span class="nav__item" *ngFor="let child of node.children">
            <span *ngIf="!child.leaf">
              <coreui-tree-progressive-pane [disabled]="loadingNodes" id="node-tree-cab-{{child['cabId']}}"
                [header]="child.displayName" (activeChange)="fetchChildrenNew(child)">
                <span *ngTemplateOutlet="treeNode; context:{nodes: child.children, parent: child}"></span>
              </coreui-tree-progressive-pane>
            </span>
            <span class="nav__item" *ngIf="child.leaf">
              <span *ngTemplateOutlet="nodeTemplate; context:{node: child}"></span>
            </span>
            <p class="load-more" (click)="fetchChildrenNew(child, true)" *ngIf="loadMore">Load More</p>
          </span>
          <p class="load-more" (click)="fetchChildrenNew(node, true)" *ngIf="loadMore">Load More</p>
        </coreui-tree-progressive-pane>
        <span class="nav__item" *ngIf="node.leaf">
          <span *ngTemplateOutlet="nodeTemplate; context:{node: node}"></span>
        </span>
      </div>
      <p class="load-more parent-node" (click)="fetchChildrenNew(currentNode, false)" *ngIf="loadMore">Load More</p>
    </ng-template>
    <coreui-scroll-indicator>
      <div coreUIScrollIndicatorTarget class="picker-nav-container">
        <coreui-tree-progressive [withIcon]="true" #treeProgressiveRef *ngIf="!loadingNodes"
          [hidden]="showSearchTree">
          <ng-container *ngTemplateOutlet="treeNode; context:{nodes: rootNodes, parent: null}"></ng-container>
        </coreui-tree-progressive>
        <div *ngIf="loadingNodes" class="loading-indicator">
          <span>
            <coreui-load-mask [active]="true">
            </coreui-load-mask>
          </span>
        </div>
        <div *ngIf="selectedFilter === 'DATA_SET_ATTRIBUTE'">
          <coreui-tree-progressive [withIcon]="true" #treeProgressiveRef *ngIf="isRegularSearch">
            <ng-container *ngTemplateOutlet="treeNode; context:{nodes: searchNodes, parent: null}"></ng-container>
          </coreui-tree-progressive>
          <ng-container *ngIf="!isRegularSearch">
            <div [hidden]="!showSearchTree"
              *ngFor="let node of searchNodes; index as nodeIdx">
              <coreui-breadcrumbs>
                <ng-container *ngFor="let path of node['path']; index as idx">
                  <span *ngIf="idx > 1" coreUIBreadcrumbLink [disabled]=true> {{path.displayName}}</span>
                  <span *ngIf="idx > 1" coreUIBreadcrumbSlash></span>
                </ng-container>
                <span coreUIBreadcrumbEnd>{{node['displayName']}}</span>
              </coreui-breadcrumbs>
              <div class="drop-list drop-list-search can-drag-anywhere pcbc-nav-tree-node" coreUIDropList
                *ngFor="let child of node.children" [data]="child" [connectedTo]="dragdropService.dropLists$ | async">
                <div coreUIDrag class="drag-element drag-element-search pcbc-nav-tree-leaf-drag">
                  <i coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></i>
                  <lib-type-icon [type]="child.type"></lib-type-icon>
                  <span class="search-text-overflow node-content"
                    [innerHtml]="child['displayName'] | highlighter:searchText"></span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div [hidden]="!showSearchTree"
          *ngIf="searchNodes && searchNodes.length && (selectedFilter === 'AUDIENCE_DEFINITION' || selectedFilter === 'AUDIENCE_LIST')">
          <div>
            <span> {{selectedFilter === 'AUDIENCE_DEFINITION' ?
              'Audience Definitions' : 'Audience Lists'}}</span>
          </div>
          <div class="drop-list drop-list-search can-drag-anywhere pcbc-nav-tree-node" coreUIDropList [data]="node"
            [connectedTo]="dragdropService.dropLists$ | async" *ngFor="let node of searchNodes">
            <div coreUIDrag class="drag-element drag-element-search pcbc-nav-tree-leaf-drag">
              <i coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></i>
              <lib-type-icon [type]="node['dataType']"></lib-type-icon>
              <span class="search-text-overflow node-content"
                [innerHtml]="node['displayName'] | highlighter:searchText"></span>
            </div>
          </div>
          <p class="load-more" (click)="attributeSearch(true)" *ngIf="loadMore">Load More</p>
        </div>
      </div>
    </coreui-scroll-indicator>
  </coreui-card>
</section>

<ng-template #nodeTemplate let-node="node">
  <ng-container *ngIf="node">
    <div class="drop-list drop-list-search can-drag-anywhere pcbc-nav-tree-node" coreUIDropList [data]="node"
      [connectedTo]="dragdropService.dropLists$ | async">
      <div coreUIDrag class="drag-element drag-element-search pcbc-nav-tree-leaf-drag">
        <i coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></i>
        <lib-type-icon [type]="node.type"></lib-type-icon>
        <span class="search-text-overflow node-content"
          [innerHtml]="node['displayName'] | highlighter:searchText"></span>
        <span *ngIf="!isProductTypeDcdp" class="node-sub-text">
          {{ node.validations?.validateDedupeType }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>